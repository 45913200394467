import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Icons } from '@levelaccess/design-system';
import {
  IDclConfig,
  ITableColumn,
  ITableConfig,
  ITableEmptyState,
  ITableRow,
  SortEvent,
} from '../../table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../../../translate/translate.service';
import { NgbTableUtilities } from '../../table/ngb-table/utilities/ngb-table.utilities';
import { DEFAULT_ENTRY_LIMITS, DEFAULT_PAGINATION_MAX_SIZE } from '../../../constants/form.constants';
import { IAuditRuleServerItem, IAuditRuleServerResponse } from '../../../../../shared/interfaces/audit-rule.interface';
import { $auditRule } from '../../../../../shared/constants/audit-rule';
import { $sortingOrder } from '../../../../../shared/constants/sort';
import { Api } from '../../../../../shared/constants/api';
import { ICellConfig } from '../../table/ngb-table/cells/base-cell/base-cell.component';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { IDropdownItem } from '../../table/ngb-table/cells/dropdown-cell/dropdown-cell.component';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import {
  CriteriaPreset,
  Divider,
  Separator,
  SuccessCriteriaFormatterService,
} from '../../../services/success-criteria-formatter.service';
import { $auditStandardSuccessCriteria } from '../../../../../shared/constants/audit-standard-success-criteria';
import {
  $auditStandard,
  AuditDbStandard,
  AuditDbStandardValues,
  AuditStandards,
  mapAuditDbStandardToAuditStandard,
} from '../../../../../shared/constants/audit-standard';
import { ISuccessCriteria } from '../../../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { SuccessCriteriaService } from '../../../services/success-criteria.service';
import { ICustomSeveritiesMap, ICustomSeverity } from '../../../../../shared/interfaces/tenant.interface';
import { $severity } from '../../../../../shared/constants/accessibility';
import { IDSSeverityCellConfig } from '../../table/ngb-table/cells/ds-severity-cell/ds-severity-cell.component';
import { $customSeverity } from '../../../../../shared/constants/tenant';
import { SeverityClass } from '../../../interfaces/custom-severities.interface';
import { IAuditStandardObjectSuccessCriteria } from '../../../../../shared/interfaces/audit-standard-success-criteria.interface';
import { IAuditStandard } from '../../../../../shared/interfaces/audit-standard.interface';

enum TableColumns {
  ruleId = 'ruleId',
  description = 'description',
  criteria = 'criteria',
  wcagSuccessCriteria = 'wcagSuccessCriteria',
  standard = 'standard',
  toolVersion = 'toolVersion',
  severity = 'severity',
  actions = 'actions',
}

@Component({
  selector: 'app-audit-rule-table',
  templateUrl: './audit-rule-table.component.html',
  providers: [NgbPaginationConfig],
})
export class AuditRuleTableComponent implements OnInit, OnDestroy {
  private _rules$: Subject<IAuditRuleServerResponse>;
  private pageSize$: BehaviorSubject<number>;
  @Input()
  private page$: BehaviorSubject<number>;
  @Input()
  private emptyStateConfig?: ITableEmptyState;

  private subscriptions: Subscription;
  private _hasCustomRulesFlag$: BehaviorSubject<boolean>;
  private _canEditRule$: BehaviorSubject<boolean>;
  private _canDeleteRule$: BehaviorSubject<boolean>;
  private _customSeverities$: BehaviorSubject<ICustomSeveritiesMap | null>;

  public pageData$: Observable<ITableRow[]>;
  public pageSizes: readonly number[];
  public paginationLabel$: Observable<string>;
  public taskCount$: Observable<number>;
  public tableConfig$: Observable<ITableConfig>;

  @Input()
  public set rules(value: IAuditRuleServerResponse) {
    this._rules$.next(value);
  }

  @Input()
  public set canEditRule(value: boolean) {
    this._canEditRule$.next(value);
  }

  @Input()
  public set canDeleteRule(value: boolean) {
    this._canDeleteRule$.next(value);
  }

  @Input()
  public set customSeverities(value: ICustomSeveritiesMap) {
    this._customSeverities$.next(value);
  }

  @Input()
  public set hasCustomRulesFlag(value: boolean) {
    this._hasCustomRulesFlag$.next(value);
  }

  public set pageSize(value: number) {
    this.pageSize$.next(value);
    this.pageSizeChanged.next(value);
  }

  public get pageSize(): number {
    return this.pageSize$.value;
  }

  public set page(value: number) {
    this.page$.next(value);
    this.viewportScroller.scrollToPosition([0, 0]);
    this.pageChanged.next(value);
  }

  public get page(): number {
    return this.page$.value;
  }

  @Output()
  public pageChanged: EventEmitter<number>;
  @Output()
  public pageSizeChanged: EventEmitter<number>;
  @Output()
  public sortChange: EventEmitter<[$auditRule, $sortingOrder]>;
  @Output()
  public auditRuleDeleted: EventEmitter<string>;

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private viewportScroller: ViewportScroller,
    private ngbPaginationConfig: NgbPaginationConfig,
    private errorHandlerService: ErrorHandlerService,
    private successCriteriaFormatterService: SuccessCriteriaFormatterService,
    private successCriteriaService: SuccessCriteriaService,
  ) {
    this.pageSizes = DEFAULT_ENTRY_LIMITS;
    this.pageSize$ = new BehaviorSubject<number>(DEFAULT_ENTRY_LIMITS[DEFAULT_ENTRY_LIMITS.length - 1]);
    this.page$ = new BehaviorSubject<number>(1);
    this.pageChanged = new EventEmitter<number>();
    this.pageSizeChanged = new EventEmitter<number>();
    this.sortChange = new EventEmitter<[$auditRule, $sortingOrder]>();
    this.auditRuleDeleted = new EventEmitter<string>();
    this._canEditRule$ = new BehaviorSubject(false);
    this._canDeleteRule$ = new BehaviorSubject(false);
    this._customSeverities$ = new BehaviorSubject(null);
    this.ngbPaginationConfig.maxSize = DEFAULT_PAGINATION_MAX_SIZE;
    this.subscriptions = new Subscription();
    this._hasCustomRulesFlag$ = new BehaviorSubject<boolean>(false);
    this._rules$ = new Subject<IAuditRuleServerResponse>();
  }

  private editAuditRule(id: string): void {
    this.router
      .navigate([id, Api.edit], { relativeTo: this.activatedRoute })
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  private getSuccessCriteriaStandards(
    successCriteria: IAuditStandardObjectSuccessCriteria,
    customRulesFlag: boolean,
  ): AuditStandards[] {
    let result: AuditStandards[];
    if (SharedCommonUtility.notNullishOrEmpty(successCriteria['standard'])) {
      result = Array.isArray(successCriteria['standard']) ? successCriteria['standard'] : [successCriteria['standard']];
    } else {
      result = Array.isArray(successCriteria[$auditStandardSuccessCriteria.auditStandards])
        ? successCriteria[$auditStandardSuccessCriteria.auditStandards].map(
            (auditStandard: IAuditStandard): AuditStandards =>
              mapAuditDbStandardToAuditStandard[auditStandard[$auditStandard.standard]],
          )
        : [mapAuditDbStandardToAuditStandard[successCriteria[$auditStandardSuccessCriteria.auditStandards]]];
    }

    if (!customRulesFlag) {
      result = result.filter((standard: AuditStandards): boolean =>
        AuditDbStandardValues.map(
          (dbStandard: AuditDbStandard): AuditStandards => mapAuditDbStandardToAuditStandard[dbStandard],
        ).includes(standard),
      );
    }
    return [...new Set(result)];
  }

  private getRuleCriteria(ruleDef: IAuditRuleServerItem, customRulesFlag: boolean): Map<AuditStandards, ISuccessCriteria[]> {
    const result: Map<AuditStandards, ISuccessCriteria[]> = new Map();
    ruleDef[$auditRule.successCriterias].forEach((criteria: IAuditStandardObjectSuccessCriteria): void => {
      const standards: AuditStandards[] = this.getSuccessCriteriaStandards(criteria, customRulesFlag);
      standards.forEach((standard: AuditStandards): void => {
        const standardCriteria: ISuccessCriteria = this.successCriteriaService.getSuccessCriteriaFromStandard(
          standard,
          criteria[$auditStandardSuccessCriteria.identifier],
        );
        if (standardCriteria) {
          result.set(standard, [...(result.get(standard) ?? []), standardCriteria]);
        }
      });
    });
    return result;
  }

  private ruleToPageRow(
    rule: IAuditRuleServerItem,
    canEditRule: boolean,
    canDeleteRule: boolean,
    customSeverities: ICustomSeveritiesMap,
    customRulesFlag: boolean,
  ): ITableRow {
    const ruleCriterias: Map<AuditStandards, ISuccessCriteria[]> = this.getRuleCriteria(rule, customRulesFlag);

    const customRulesData = (): Record<string, IDclConfig<ICellConfig>> => ({
      [TableColumns.criteria]: NgbTableUtilities.htmlCell({
        text: [
          ...new Set(
            rule[$auditRule.successCriterias]?.flatMap((successCriteria: IAuditStandardObjectSuccessCriteria) => {
              return this.successCriteriaFormatterService.toDisplayCriterias({
                standards: this.getSuccessCriteriaStandards(successCriteria, customRulesFlag),
                identifiers: [successCriteria[$auditStandardSuccessCriteria.identifier]],
                separator: Separator.newLine,
                preset: CriteriaPreset.criteriaIdentifierWithHandle,
                divider: Divider.hyphen,
              });
            }),
          ),
        ].join('\n'),
        classes: ['pre-line'],
      }),
      [TableColumns.standard]: NgbTableUtilities.htmlCell({
        text: this.successCriteriaFormatterService.getAccessibilityStandards(ruleCriterias).join('\n'),
        classes: ['pre-line'],
      }),
    });

    const legacyData = (): Record<string, IDclConfig<ICellConfig>> => ({
      [TableColumns.wcagSuccessCriteria]: NgbTableUtilities.htmlCell({
        text: [
          ...new Set(
            rule[$auditRule.successCriterias]?.flatMap((successCriteria: IAuditStandardObjectSuccessCriteria) => {
              return this.successCriteriaFormatterService.toDisplayCriterias({
                standards: this.getSuccessCriteriaStandards(successCriteria, customRulesFlag),
                identifiers: [successCriteria[$auditStandardSuccessCriteria.identifier]],
                separator: Separator.newLine,
                preset: CriteriaPreset.criteriaIdentifierWithLevel,
              });
            }),
          ),
        ].join('\n'),
        classes: ['pre-line'],
      }),
      [TableColumns.toolVersion]: NgbTableUtilities.htmlCell({
        text: [
          ...new Set(
            rule.successCriterias?.flatMap((successCriteria: IAuditStandardObjectSuccessCriteria) => {
              return this.successCriteriaFormatterService.toDisplayCriterias({
                standards: this.getSuccessCriteriaStandards(successCriteria, customRulesFlag),
                identifiers: [successCriteria[$auditStandardSuccessCriteria.identifier]],
                separator: Separator.newLine,
                preset: CriteriaPreset.criteriaVersions,
              });
            }),
          ),
        ].join('\n'),
        classes: ['pre-line'],
      }),
    });

    const getSeverityConfig = (severity: $severity): Partial<IDSSeverityCellConfig> => {
      const customSeverity: ICustomSeverity = customSeverities.get(severity);
      if (customSeverity[$customSeverity.system]) {
        return {};
      }

      const highSeverity: ICustomSeverity = customSeverities.get($severity.high);
      if (customSeverity[$customSeverity.rank] === highSeverity[$customSeverity.rank] - 1) {
        return {
          class: SeverityClass.moderate,
          icon: Icons.ChevronUp,
        };
      }
      return {
        class: SeverityClass.minor,
        icon: Icons.ChevronDown,
      };
    };

    const data: Record<string, IDclConfig<ICellConfig>> = {
      [TableColumns.ruleId]: NgbTableUtilities.linkCell({
        text: rule.ruleId,
        attributes: {
          routerLink: `./${rule.ruleId}/${Api.view}`,
        },
      }),
      [TableColumns.description]: NgbTableUtilities.textCell({ text: rule.description }),
      ...(customRulesFlag ? customRulesData() : legacyData()),
      [TableColumns.severity]: customSeverities
        ? NgbTableUtilities.DSSeverityCell({
            severity: rule.severity,
            customSeverity: customSeverities.get(rule.severity),
            ...getSeverityConfig(rule.severity),
          })
        : NgbTableUtilities.severityCell({ severity: rule.severity }),
    };

    const editAuditRuleItem: IDropdownItem = {
      label: this.translateService.instant('action_edit'),
      clickListener: this.editAuditRule.bind(this, rule[$auditRule.ruleId]),
    };
    const deleteAuditRuleItem: IDropdownItem = {
      label: this.translateService.instant('action_delete'),
      clickListener: () => this.auditRuleDeleted.emit(rule[$auditRule.ruleId]),
    };

    const actionItems: IDropdownItem[] = [
      ...(canEditRule ? [editAuditRuleItem] : []),
      ...(canDeleteRule ? [deleteAuditRuleItem] : []),
    ];

    if (SharedCommonUtility.notNullishOrEmpty(actionItems)) {
      data[TableColumns.actions] = NgbTableUtilities.dropdownCell({
        ellipsisIcon: true,
        classes: ['btn-link', 'px-0'],
        dropdownItems: actionItems,
        ariaLabel: this.translateService.instant('action_for_rule', [rule[$auditRule.ruleId]]),
      });
    }

    return { data };
  }

  public getTableConfig(canEditRule: boolean, canDeleteRule: boolean, customRulesFlag: boolean): ITableConfig {
    const customRulesColumns: Record<string, ITableColumn> = {
      [TableColumns.criteria]: {
        translationKey: 'label_criteria',
        styles: {
          maxWidth: '25%',
          width: '25%',
        },
      },
      [TableColumns.standard]: {
        translationKey: 'label_standard',
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      },
    };

    const legacyColumns: Record<string, ITableColumn> = {
      [TableColumns.wcagSuccessCriteria]: {
        translationKey: 'issue_field_successCriteria',
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      },
      [TableColumns.toolVersion]: {
        translationKey: 'version',
        styles: {
          maxWidth: '7.5%',
          width: '7.5%',
        },
      },
    };

    const columns: Record<string, ITableColumn> = {
      [TableColumns.ruleId]: {
        translationKey: 'issue_field_ruleId',
        sortingEnabled: true,
        styles: {
          maxWidth: '15%',
          width: '15%',
        },
      },
      [TableColumns.description]: {
        translationKey: 'issue_description',
        sortingEnabled: true,
        styles: {
          maxWidth: '1px',
          minWidth: '300px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
      ...(customRulesFlag ? customRulesColumns : legacyColumns),
      [TableColumns.severity]: {
        translationKey: 'issue_field_severity',
        sortingEnabled: false,
        styles: {
          maxWidth: '10%',
          width: '10%',
        },
      },
    };

    if (canEditRule || canDeleteRule) {
      columns[TableColumns.actions] = {
        translationKey: 'table_column_actions',
        styles: {
          width: '7.5%',
          maxWidth: '7.5%',
        },
      };
    }

    return {
      columns: columns,
      caption: this.translateService.instant('table_caption_tasks'),
      emptyState: SharedCommonUtility.notNullish(this.emptyStateConfig)
        ? this.emptyStateConfig
        : {
            title: this.translateService.instant('no_search_results_available'),
            subtitle: this.translateService.instant('label_no_search_results_hint'),
          },
    };
  }

  public onSort(event: SortEvent): void {
    this.sortChange.next([$auditRule[event.column], event.direction]);
  }

  public ngOnInit(): void {
    this.tableConfig$ = combineLatest([this._canEditRule$, this._canDeleteRule$, this._hasCustomRulesFlag$]).pipe(
      map(
        ([canEditRule, canDeleteRule, customRulesFlag]: [boolean, boolean, boolean]): ITableConfig =>
          this.getTableConfig(canEditRule, canDeleteRule, customRulesFlag),
      ),
    );

    this.pageData$ = combineLatest([
      this._rules$,
      this._canEditRule$,
      this._canDeleteRule$,
      this._customSeverities$,
      this._hasCustomRulesFlag$,
    ]).pipe(
      map(
        ([rules, canEditRule, canDeleteRule, customSeverities, customRuleFlag]: [
          IAuditRuleServerResponse,
          boolean,
          boolean,
          ICustomSeveritiesMap,
          boolean,
        ]) => {
          return (rules.auditRules as IAuditRuleServerItem[]).map(
            (auditRule: IAuditRuleServerItem): ITableRow =>
              this.ruleToPageRow(auditRule, canEditRule, canDeleteRule, customSeverities, customRuleFlag),
          );
        },
      ),
    );

    this.taskCount$ = this._rules$.pipe(map((rules: IAuditRuleServerResponse) => rules._total));

    this.paginationLabel$ = combineLatest([this.taskCount$, this.page$, this.pageSize$]).pipe(
      map(([taskCount, page, pageSize]: [number, number, number]) => {
        const startIndex = Math.min((page - 1) * pageSize + 1, taskCount);
        const lastIndex = Math.min(startIndex + pageSize - 1, taskCount);
        return this.translateService.instant('n_of_t', [`${startIndex} - ${lastIndex}`, String(taskCount)]);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
