import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Type, ViewChild } from '@angular/core';
import { BaseCellComponent, ICellConfig, setupCell } from '../base-cell/base-cell.component';
import { IDclConfig } from '../../utilities/ngb-table.interface';

export interface IComposedCellConfig extends ICellConfig {
  configs: IDclConfig<any>[];
}

@Component({
  selector: 'table-cell-composed',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="d-flex align-items-center position-relative" #composedRef>
      <ng-container *ngFor="let type of types; let i = index">
        <dcl-wrapper [type]="type" [config]="configs[i]"></dcl-wrapper>
      </ng-container>
    </div>
  `,
})
export class ComposedCellComponent extends BaseCellComponent implements AfterViewInit {
  @Input() public config: IComposedCellConfig;
  @ViewChild('composedRef')
  public element: ElementRef<HTMLElement>;

  get types(): Type<any>[] {
    return this.config.configs.map((config: IDclConfig<any>) => config.type);
  }

  get configs(): ICellConfig[] {
    return this.config.configs.map((config: IDclConfig<any>) => config.config);
  }

  public ngAfterViewInit(): void {
    setupCell(this.renderer, this.config, this.element.nativeElement);
  }
}
