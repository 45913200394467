import { commentType } from './comment';
import { RuleAuditHistoryAction } from './rule-audit-history';

export enum $auditIssueFields {
  issueId = 'issueId',
  severity = 'severity',
  summary = 'summary',
  description = 'description',
  screen = 'screen',
  files = 'files',
  attachments = 'attachments',
  url = 'url',
  issueFrequency = 'issueFrequency',
  ATCombination = 'ATCombination',
  stepsToReproduce = 'stepsToReproduce',
  actualResult = 'actualResult',
  expectedResult = 'expectedResult',
  screenshot = 'screenshot',
  successCriteria = 'successCriteria',
  conformanceLevel = 'conformanceLevel',
  conformanceLevelAndVersion = 'conformanceLevelAndVersion',
  instances = 'instances',
  disabilityAffected = 'disabilityAffected',
  userImpact = 'userImpact',
  recommendation = 'recommendation',
  effort = 'effort',
  status = 'status',
  actions = 'actions',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  ruleId = 'ruleId',
  task = 'task',
  age = 'age',
  category = 'category',
  externalId = 'externalId',
  xpath = 'xpath',
  codeSnippet = 'codeSnippet',
  component = 'component',
  taskId = 'taskId',
  viewport = 'viewport',
  matchAllAffectedDisabilities = 'matchAllAffectedDisabilities',
  multipleDisabilitiesAffected = 'multipleDisabilitiesAffected',
  evaluationStandard = 'evaluationStandard',
  criteria = 'criteria',
}

export enum $formatColumns {
  singleDescriptionColumn = 'singleDescriptionColumn',
  separateColumns = 'separateColumns',
}

export type ManualEvaluationAuditType =
  | commentType.manualAuditIssueComment
  | RuleAuditHistoryAction.statusUpdate
  | RuleAuditHistoryAction.create
  | commentType.taskCreate
  | commentType.taskDelete;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualEvaluationAuditType: Record<ManualEvaluationAuditType, ManualEvaluationAuditType> = {
  [commentType.manualAuditIssueComment]: commentType.manualAuditIssueComment,
  [commentType.taskCreate]: commentType.taskCreate,
  [commentType.taskDelete]: commentType.taskDelete,
  [RuleAuditHistoryAction.statusUpdate]: RuleAuditHistoryAction.statusUpdate,
  [RuleAuditHistoryAction.create]: RuleAuditHistoryAction.create,
};

export enum $allowedManualAuditAttachmentExt {
  // images
  jpg = '.jpg',
  jpeg = '.jpeg',
  png = '.png',

  // others
  csv = '.csv',
  xlsx = '.xlsx',
}

export const ALLOWED_MANUAL_AUDIT_ATTACHMENT_IMAGES_EXTENSIONS: Readonly<$allowedManualAuditAttachmentExt[]> = Object.freeze([
  $allowedManualAuditAttachmentExt.jpg,
  $allowedManualAuditAttachmentExt.jpeg,
  $allowedManualAuditAttachmentExt.png,
]);

export const ALLOWED_MANUAL_AUDIT_ATTACHMENT_IMAGES_EXTENSIONS_SET: Readonly<Set<string>> = new Set(
  ALLOWED_MANUAL_AUDIT_ATTACHMENT_IMAGES_EXTENSIONS,
);
