import { Component, Input } from '@angular/core';
import { DsColors, Icons, IconStyles } from '@levelaccess/design-system';

import { BaseCellComponent, ICellConfig } from '../base-cell/base-cell.component';
import { $severity } from '../../../../../../../shared/constants/accessibility';
import { ICustomSeverity } from '../../../../../../../shared/interfaces/tenant.interface';
import { SeverityClass } from '../../../../../interfaces/custom-severities.interface';
import { $customSeverity } from '../../../../../../../shared/constants/tenant';
import { SharedCommonUtility } from '../../../../../../../shared/utils/common.utility';

export interface IDSSeverityCellConfig extends ICellConfig {
  severity: $severity;
  customSeverity: ICustomSeverity;
  customSeverityKeysSortedByRank?: $severity[];
  icon?: Icons;
  class?: SeverityClass;
}

@Component({
  selector: 'table-cell-custom-severity',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div #ref class="ds-severity-cell">
      <ds-icon
        class="margin-right-xs {{ config.class ?? systemSeverityClass }}"
        [icon]="config.icon ?? systemSeverityIcon"
        [iconStyle]="IconStyles.Solid"
      ></ds-icon>
      <span>{{ severityText | titlecase }}</span>
    </div>
  `,
})
export class DsSeverityCellComponent extends BaseCellComponent {
  protected readonly Icons: typeof Icons = Icons;
  protected readonly IconStyles: typeof IconStyles = IconStyles;
  protected readonly colors: typeof DsColors = DsColors;
  protected readonly customSeveritiesClassAndIcon: { class: SeverityClass; icon: Icons }[] = [
    { class: SeverityClass.moderate, icon: Icons.ChevronUp },
    { class: SeverityClass.minor, icon: Icons.ChevronDown },
  ];

  @Input() public config: IDSSeverityCellConfig;

  private getCustomSeverityIndex(severity: $severity): number {
    return this.config.customSeverityKeysSortedByRank?.indexOf(severity) ?? -1;
  }

  protected get severityText(): string {
    return SharedCommonUtility.notNullishOrEmpty(this.config.text)
      ? this.config.text
      : this.config.customSeverity[$customSeverity.label];
  }

  protected get systemSeverityIcon(): Icons | undefined {
    switch (this.config.severity) {
      case $severity.critical:
        return Icons.CircleMinus;
      case $severity.high:
        return Icons.ChevronsUp;
      case $severity.low:
        return Icons.ChevronsDown;
      case $severity.custom2:
      case $severity.custom1:
        if (this.config.customSeverityKeysSortedByRank?.includes(this.config.severity)) {
          const customIndex: number = this.getCustomSeverityIndex(this.config.severity);
          return this.customSeveritiesClassAndIcon[customIndex].icon;
        }
        return this.config.icon;
      case $severity.info:
      default:
        return this.config.icon;
    }
  }

  protected get systemSeverityClass(): SeverityClass | undefined {
    switch (this.config.severity) {
      case $severity.critical:
        return SeverityClass.critical;
      case $severity.high:
        return SeverityClass.high;
      case $severity.low:
        return SeverityClass.low;
      case $severity.custom2:
      case $severity.custom1:
        if (this.config.customSeverityKeysSortedByRank?.includes(this.config.severity)) {
          const customIndex: number = this.getCustomSeverityIndex(this.config.severity);
          return this.customSeveritiesClassAndIcon[customIndex].class;
        }
        return this.config.class;
      case $severity.info:
      default:
        return this.config.class;
    }
  }
}
