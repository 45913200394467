import { SIZE_1_MB } from './uploads';

export enum $manualAuditResponse {
  manualAudits = 'manualAudits',
  _total = '_total',
}

export enum $manualAudit {
  _id = '_id',
  file = 'file',
  title = 'title',
  digitalProperty = 'digitalProperty',
  status = 'status',
  createdBy = 'createdBy',
  fileName = 'fileName',
  isArchived = 'isArchived',
  archivedBy = 'archivedBy',
  workspace = 'workspace',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  issuesCount = 'issuesCount',
  scope = 'scope',
  testGuide = 'testGuide',
  evaluationMedium = 'evaluationMedium',
  evaluationStandards = 'evaluationStandards',
  pages = 'pages',
  origin = 'origin',
  conformanceScore = 'conformanceScore',
}

export enum $manualAuditCreate {
  title = 'title',
  scope = 'scope',
}

export const MAX_UPLOAD_SIZE: number = 200 * SIZE_1_MB;
