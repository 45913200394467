import { Component, Input } from '@angular/core';
import { IAuditRule, IAuditRuleBase, IAuditStandard } from '../../../../../shared/interfaces/audit-rule.interface';
import { AuditToolNames } from '../../../../../shared/constants/audit-tool';
import { ICustomAuditRuleWithStandards } from '../../../../../shared/interfaces/custom-audit-rule.interface';
import { $auditRule } from '../../../../../shared/constants/audit-rule';
import {
  CriteriaPreset,
  Divider,
  Separator,
  SuccessCriteriaFormatterService,
} from '../../../services/success-criteria-formatter.service';
import {
  $auditStandard,
  AuditDbStandard,
  AuditStandards,
  mapAuditDbStandardToAuditStandard,
} from '../../../../../shared/constants/audit-standard';
import { $successCriteria } from '../../../../../shared/audits/definitions/success-criteria/constants';
import { ICustomSeveritiesMap } from '../../../../../shared/interfaces/tenant.interface';
import { $auditStandardSuccessCriteria } from '../../../../../shared/constants/audit-standard-success-criteria';
import { ISuccessCriteria } from '../../../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { SuccessCriteriaService } from '../../../services/success-criteria.service';
import { IAuditStandardObjectSuccessCriteria } from '../../../../../shared/interfaces/audit-standard-success-criteria.interface';

export interface ISharedAuditRule {
  libraryName: string;
  isAccessipediaRule: boolean;
  testingMedium: string;
}

export interface IAuditRuleWithLibraryName extends IAuditRule, ISharedAuditRule {}

export interface ICustomAuditRuleWithLibraryName extends ICustomAuditRuleWithStandards, ISharedAuditRule {}

export type IRuleInput =
  | IAuditRuleWithLibraryName
  | (ICustomAuditRuleWithLibraryName &
      Pick<
        IAuditRuleBase,
        | $auditRule.compliantCodeExample
        | $auditRule.nonCompliantCodeExample
        | $auditRule.techniques
        | $auditRule.applicableElementsSelectors
      >);

@Component({
  selector: 'app-audit-rule-view',
  templateUrl: './audit-rule-view.component.html',
})
export class AuditRuleViewComponent {
  @Input() public rule: IRuleInput;
  @Input() public customSeverities: ICustomSeveritiesMap | null = null;
  @Input() public showCustomRulesData: boolean = false;

  public AuditToolNames: typeof AuditToolNames = AuditToolNames;

  constructor(
    private successCriteriaFormatterService: SuccessCriteriaFormatterService,
    private successCriteriaService: SuccessCriteriaService,
  ) {}

  public displayWCAGOrNACriteria(standard: IAuditStandard): string {
    return this.successCriteriaFormatterService.toDisplayCriterias({
      standards: [standard.id],
      identifiers: [
        standard?.[standard.id]?.[$successCriteria.num],
        standard?.[AuditStandards.notApplicable]?.[$successCriteria.num],
      ],
      separator: Separator.newLine,
      preset: CriteriaPreset.criteriaIdentifierWithLevel,
    });
  }

  public getStandardVersions(standard: IAuditStandard): string[] | undefined {
    return standard[standard.id]?.[$successCriteria.versions];
  }

  private getRuleStandardCriteriaMap(): Map<AuditStandards, ISuccessCriteria[]> {
    const result: Map<AuditStandards, ISuccessCriteria[]> = new Map();
    this.rule[$auditRule.successCriterias].forEach((criteria: IAuditStandardObjectSuccessCriteria): void => {
      const standards: AuditStandards[] = this.getSuccessCriteriaStandards(criteria);
      standards.forEach((standard: AuditStandards): void => {
        const standardCriteria: ISuccessCriteria = this.successCriteriaService.getSuccessCriteriaFromStandard(
          standard,
          criteria[$auditStandardSuccessCriteria.identifier],
        );
        if (standardCriteria) {
          result.set(standard, [...(result.get(standard) ?? []), standardCriteria]);
        }
      });
    });
    return result;
  }

  public getRuleAccessibilityStandards(): string[] {
    const ruleCriterias: Map<AuditStandards, ISuccessCriteria[]> = this.getRuleStandardCriteriaMap();
    return this.successCriteriaFormatterService.getAccessibilityStandards(ruleCriterias);
  }

  private getSuccessCriteriaStandards(successCriteria: IAuditStandardObjectSuccessCriteria): AuditStandards[] {
    return [
      ...new Set(
        successCriteria[$auditStandardSuccessCriteria.auditStandards].map(
          (std: any): AuditStandards => mapAuditDbStandardToAuditStandard[std[$auditStandard.standard] as AuditDbStandard],
        ),
      ),
    ];
  }

  public getRuleCriteria(): string[] {
    return [
      ...new Set<string>(
        this.rule[$auditRule.successCriterias]?.flatMap((successCriteria: IAuditStandardObjectSuccessCriteria): string[] => {
          return this.getSuccessCriteriaStandards(successCriteria).map((standard: AuditStandards) =>
            this.successCriteriaFormatterService.toDisplayCriterias({
              standards: [standard],
              identifiers: [successCriteria[$auditStandardSuccessCriteria.identifier]],
              separator: Separator.newLine,
              preset: CriteriaPreset.criteriaIdentifierWithHandle,
              divider: Divider.hyphen,
            }),
          );
        }),
      ),
    ];
  }
}
