import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { omit, pick } from 'lodash';
import { DsButtonVariants } from '@levelaccess/design-system';

import { $auditRule, AuditRuleAction } from '../../../../../shared/constants/audit-rule';
import { ISuccessCriteria } from '../../../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { $successCriteria } from '../../../../../shared/audits/definitions/success-criteria/constants';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { IAuditRule, IAuditRuleLibrary } from '../../../../../shared/interfaces/audit-rule.interface';
import { AuditRuleService } from '../../../services/audit-rule.service';
import { TranslateService } from '../../../translate/translate.service';
import { $severity, customSeverities } from '../../../../../shared/constants/accessibility';
import { Api } from '../../../../../shared/constants/api';
import { ErrorMessageService } from '../../../services/error-message.service';
import { AuditFindingDisabilityAffected, AuditFindingIssueEffort } from '../../../../../shared/constants/audit-finding';
import { INameValue } from '../../../../../shared/interfaces/common.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { DesignRuleLibrary } from '../../../../../shared/constants/design-rule';
import { AngularUtility } from '../../../utility/angular.utility';
import { librariesWithTestData, ruleLibraryCodeNames } from '../../../../../shared/constants/rule-library';
import { SuccessCriteriaService } from '../../../services/success-criteria.service';
import { MasterLibraryHelper } from '../../../admin/master-library/master-library.helper';
import { CustomAuditRuleService } from '../../../services/custom-audit-rule.service';
import { AuditRuleUpsertRequest, IAuditRuleFormSubmission } from '../../../interfaces/audit-rule.interface';
import { ICustomAuditRuleWithLibraryName } from '../audit-rule-view/audit-rule-view.component';
import { IEditCustomAuditRules } from '../../../../../shared/interfaces/custom-audit-rule.interface';
import { IAuditRuleEditRequest } from '../../../../../shared/interfaces/audit-rule.request.interface';
import {
  EVALUATION_MEDIUM_TO_RULE_LIBRARY_CODE,
  EVALUATION_MEDIUMS,
  EvaluationMediums,
} from '../../../../../shared/constants/manual-evaluation';
import { $manualAudit } from '../../../../../shared/constants/manual-audit';
import { AuditStandards } from '../../../../../shared/constants/audit-standard';
import { FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';
import { FeatureFlagCollection } from '../../../../../shared/interfaces/feature-flag.interface';
import { ICustomSeveritiesMap, ICustomSeverity } from '../../../../../shared/interfaces/tenant.interface';
import { TenantSeveritiesService } from '../../../services/tenant-severities.service';
import { SelectFieldContext } from '../../common-select/common-select.component';

/**
 * This form was extracted from the old create-rule.component.ts into a reusable component to follow DRY principle.
 * Master library rules and custom audit rules now reuse it on their own creation views, so most of the logic is shared.
 * Note that there is also special logic for the custom audit rules to meet particular needs.
 */

interface IAuditRuleForm {
  [$auditRule.ruleId]: FormControl<string>;
  [$auditRule.description]: FormControl<string>;
  [$auditRule.successCriterias]: FormControl<string[]>;
  [$auditRule.ruleLibrary]: FormControl<string>;
  [$auditRule.category]: FormControl<string>;
  [$auditRule.severity]: FormControl<$severity>;
  [$auditRule.recommendation]: FormControl<string>;
  [$auditRule.stepsToReproduce]: FormControl<string>;
  [$auditRule.actualResult]: FormControl<string>;
  [$auditRule.functionalImpact]: FormControl<AuditFindingIssueEffort>;
  [$auditRule.disabilitiesAffected]: FormControl<AuditFindingDisabilityAffected[]>;
  [$auditRule.testId]: FormControl<string>;
  [$auditRule.testName]: FormControl<string>;
  [$auditRule.testManualSteps]: FormControl<string>;
  [$auditRule.compliantCodeExample]: FormControl<string>;
  [$auditRule.nonCompliantCodeExample]: FormControl<string>;
  [$auditRule.techniques]: FormControl<string>;
  [$manualAudit.evaluationMedium]: FormControl<string>;
}

@Component({
  selector: 'app-upsert-audit-rule-form',
  templateUrl: './upsert-audit-rule-form.component.html',
})
export class UpsertAuditRuleFormComponent implements AfterViewChecked, OnInit, OnDestroy, AfterViewInit {
  private librariesRaw$: Observable<IAuditRuleLibrary[]>;
  private subscription: Subscription;
  private existingRuleIds: string[] | null;
  public selectedLibrary$: Observable<IAuditRuleLibrary>;
  public selectedMedium$: Observable<string>;

  @Input() public isCustomAuditRule: boolean;

  @Input() public action: AuditRuleAction;

  @Input()
  public set auditRuleData(auditRule: IAuditRule | ICustomAuditRuleWithLibraryName) {
    this.auditRule = auditRule;
    this.setForm();
  }

  @Output()
  public save: EventEmitter<IAuditRuleFormSubmission>;

  public form: FormGroup<IAuditRuleForm>;
  public formValidationRequest$: Subject<void>;
  public successCriterias: ISuccessCriteria[];
  public libraries$: Observable<INameValue[]>;
  public categories$: Observable<string[]>;
  public severities$: Observable<INameValue[]>;
  public functionalImpacts: INameValue[];
  public disabilities: INameValue[];
  public auditRule: IAuditRule | ICustomAuditRuleWithLibraryName;
  public currentLibrary: string;
  public showTestFields: boolean;
  public Api: typeof Api = Api;
  public $auditRule: typeof $auditRule = $auditRule;
  public $manualAudit: typeof $manualAudit = $manualAudit;
  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public ruleLibraryCodeNames: typeof ruleLibraryCodeNames = ruleLibraryCodeNames;
  public AuditRuleAction: typeof AuditRuleAction = AuditRuleAction;
  public accessibilityStandards$: Observable<string[]>;
  public evaluationMediums: string[];
  public isWebLaLibrary: boolean;

  public constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private auditRuleService: AuditRuleService,
    private customAuditRuleService: CustomAuditRuleService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorMessageService: ErrorMessageService,
    private element: ElementRef<Element>,
    private successCriteriaService: SuccessCriteriaService,
    private featureFlagService: FeatureFlagService,
    private tenantSeveritiesService: TenantSeveritiesService,
  ) {
    this.form = this.createForm();
    this.formValidationRequest$ = new Subject();
    this.$auditRule = $auditRule;
    this.successCriterias = this.successCriteriaService.getAllSupportedCriteriaFromStandard();

    this.functionalImpacts = Object.keys(AuditFindingIssueEffort).map((key: string) => ({
      value: key,
      name: `label_issue_effort_${key}`,
    }));

    this.disabilities = Object.keys(AuditFindingDisabilityAffected).map((key: string) => ({
      value: key,
      name: `issue_field_disability_${key}`,
    }));

    this.subscription = new Subscription();
    this.existingRuleIds = null;
    this.save = new EventEmitter<IAuditRuleFormSubmission>();
    this.evaluationMediums = EVALUATION_MEDIUMS.filter(
      (medium: EvaluationMediums) => ![EvaluationMediums.hardware, EvaluationMediums.pv_web].includes(medium),
    ).map((medium: EvaluationMediums) => medium.toString());
  }

  private createForm(): FormGroup<IAuditRuleForm> {
    return this.formBuilder.group({
      [$auditRule.ruleId]: this.formBuilder.control(null, [CustomValidators.required, CustomValidators.validateIsEmpty]),
      [$auditRule.description]: this.formBuilder.control(null, [CustomValidators.validateIsEmpty]),
      [$auditRule.successCriterias]: this.formBuilder.control(null, [
        CustomValidators.required,
        CustomValidators.validateIsEmpty,
      ]),
      [$auditRule.ruleLibrary]: this.formBuilder.control(null, [CustomValidators.validateIsEmpty]),
      [$auditRule.category]: this.formBuilder.control(null, [CustomValidators.required, CustomValidators.validateIsEmpty]),
      [$auditRule.severity]: this.formBuilder.control(null, [CustomValidators.validateIsEmpty]),
      [$auditRule.recommendation]: this.formBuilder.control('', [CustomValidators.required, CustomValidators.validateIsEmpty]),
      [$auditRule.stepsToReproduce]: this.formBuilder.control('', [CustomValidators.required, CustomValidators.validateIsEmpty]),
      [$auditRule.actualResult]: this.formBuilder.control('', [CustomValidators.required, CustomValidators.validateIsEmpty]),
      [$auditRule.functionalImpact]: this.formBuilder.control(null, [
        CustomValidators.required,
        CustomValidators.validateIsEmpty,
      ]),
      [$auditRule.disabilitiesAffected]: this.formBuilder.control(null, [
        CustomValidators.required,
        CustomValidators.validateIsEmpty,
      ]),
      [$auditRule.testId]: this.formBuilder.control('', []),
      [$auditRule.testName]: this.formBuilder.control('', []),
      [$auditRule.testManualSteps]: this.formBuilder.control('', []),
      [$auditRule.compliantCodeExample]: this.formBuilder.control('', [CustomValidators.maxLength(2000)]),
      [$auditRule.nonCompliantCodeExample]: this.formBuilder.control('', [CustomValidators.maxLength(2000)]),
      [$auditRule.techniques]: this.formBuilder.control('', [CustomValidators.maxLength(2000)]),
      [$manualAudit.evaluationMedium]: this.formBuilder.control(null, []),
    });
  }

  private setForm(): void {
    if (this.auditRule) {
      let ruleSuccessCriterias: string[];
      if ($auditRule.wcagCriteria in this.auditRule) {
        // only admin portal rules have this field populated atm
        ruleSuccessCriterias = this.auditRule[$auditRule.wcagCriteria];
      } else if ($auditRule.successCriterias in this.auditRule) {
        ruleSuccessCriterias = this.auditRule[$auditRule.successCriterias].map((cr: any) => cr.identifier);
      }

      this.form.patchValue(
        pick(
          {
            ...this.auditRule,
            [$auditRule.successCriterias]: ruleSuccessCriterias,
            [$auditRule.functionalImpact]: AuditFindingIssueEffort[this.auditRule.functionalImpact],
            [$auditRule.severity]: $severity[this.auditRule.severity],
            [$auditRule.techniques]: this.auditRule[$auditRule.techniques]?.join('\n') ?? '',
          },
          Object.keys(this.form.controls),
        ),
      );
    }
  }

  private startPropagatingFormChangesToQueryParams(): void {
    this.subscription.add(
      this.form.valueChanges
        .pipe(
          withLatestFrom(this.activatedRoute.queryParams),
          map(([values, queryParams]: [Record<string, any>, Params]) => {
            const newQueryParams: Record<string, any> = {
              ...queryParams,
              [$auditRule.ruleLibrary]: values[$auditRule.ruleLibrary],
              [$auditRule.ruleId]: values[$auditRule.ruleId],
              [$auditRule.description]: values[$auditRule.description],
              [$auditRule.severity]: values[$auditRule.severity],
            };
            this.router
              .navigate([], {
                queryParams: newQueryParams,
                skipLocationChange: true,
              })
              .then();
            return null;
          }),
        )
        .subscribe(),
    );
  }

  private updateTestRelatedFormFields(displayTestFields: boolean): void {
    if (displayTestFields) {
      this.form.get($auditRule.testId).setValidators([CustomValidators.required, CustomValidators.validateIsEmpty]);
      this.form.get($auditRule.testName).setValidators([CustomValidators.required, CustomValidators.validateIsEmpty]);
      this.form.get($auditRule.testManualSteps).setValidators([CustomValidators.required, CustomValidators.validateIsEmpty]);
    } else {
      this.form.get($auditRule.testId).clearValidators();
      this.form.get($auditRule.testId).setErrors(null);
      this.form.get($auditRule.testName).setErrors(null);
      this.form.get($auditRule.testManualSteps).setErrors(null);
    }
  }

  private initializeLibrariesObservables(): void {
    this.librariesRaw$ = (
      this.isCustomAuditRule ? this.customAuditRuleService.getCustomAuditRulesLibrary() : this.auditRuleService.getLibraries()
    ).pipe(AngularUtility.shareRef<IAuditRuleLibrary[]>());

    this.libraries$ = this.librariesRaw$.pipe(
      map((libraries: IAuditRuleLibrary[]) =>
        libraries.map((lib: IAuditRuleLibrary): { name: string; value: string } => ({
          value: lib._id,
          name: MasterLibraryHelper.getLibraryName(lib.name, this.translateService),
        })),
      ),
      tap((): void => {
        this.form.get($auditRule.ruleLibrary).setValue(this.currentLibrary);
      }),
    );

    const ruleLibraryValueChanges$: Observable<string> = this.form
      .get($auditRule.ruleLibrary)
      .valueChanges.pipe(filter((value: string): boolean => SharedCommonUtility.notNullishOrEmpty(value)));
    this.selectedLibrary$ = combineLatest([ruleLibraryValueChanges$, this.librariesRaw$]).pipe(
      map(([selectedLibrary, allLibraries]: [string, IAuditRuleLibrary[]]): IAuditRuleLibrary => {
        return allLibraries.find((lib: IAuditRuleLibrary): boolean => lib._id === selectedLibrary);
      }),
      tap((library: IAuditRuleLibrary): void => {
        this.isWebLaLibrary = library.code === ruleLibraryCodeNames.web_la;
        if (this.isCustomAuditRule) {
          this.form
            .get($manualAudit.evaluationMedium)
            .setValue(library.mediaType.toString(), { emitEvent: this.action === AuditRuleAction.Create });
        }
      }),
    );

    const criteriaChanges$: Observable<string[]> = this.form.get($auditRule.successCriterias).valueChanges;
    this.accessibilityStandards$ = criteriaChanges$.pipe(
      distinctUntilChanged(),
      map((identifiers: string[]): string[] => this.getFormattedStandardsByIdentifiers(identifiers)),
    );

    this.categories$ = this.selectedLibrary$.pipe(
      map((library: IAuditRuleLibrary): string[] => {
        this.form.get($auditRule.category).setValue(library.categories[0]);
        return library.categories;
      }),
    );

    this.subscription.add(
      this.selectedLibrary$
        .pipe(
          map((library: IAuditRuleLibrary): boolean => {
            return librariesWithTestData.includes(library.code as ruleLibraryCodeNames);
          }),
        )
        .subscribe((libraryContainsTestData: boolean): void => {
          this.showTestFields = libraryContainsTestData || this.isCustomAuditRule;
          this.updateTestRelatedFormFields(libraryContainsTestData);
        }),
    );

    this.selectedMedium$ = this.form.get($manualAudit.evaluationMedium).valueChanges;
    this.subscription.add(
      combineLatest([this.selectedMedium$, this.librariesRaw$]).subscribe(
        ([selectedMedium, allLibraries]: [string, IAuditRuleLibrary[]]): void => {
          const code: ruleLibraryCodeNames = EVALUATION_MEDIUM_TO_RULE_LIBRARY_CODE[selectedMedium];
          const library: IAuditRuleLibrary = allLibraries.find((lib: IAuditRuleLibrary): boolean => lib.code === code);
          this.isWebLaLibrary = library.code === ruleLibraryCodeNames.web_la;

          if (this.isCustomAuditRule) {
            this.form.get($auditRule.ruleLibrary).setValue(library._id, { emitEvent: this.action === AuditRuleAction.Update });
            if (this.action === AuditRuleAction.Create) {
              this.categories$ = of(library.categories);
              this.form.get($auditRule.category).setValue(library.categories[0]);
            }
          }
        },
      ),
    );
  }

  private customAuditRuleInitializer(): void {
    this.form.get($auditRule.ruleId).addAsyncValidators(this.customAuditRuleService.validateRuleIdUniqueness());
    this.form.get($auditRule.ruleId).updateValueAndValidity();
    this.form.get($auditRule.ruleLibrary).setValidators(null);
    this.form.get($manualAudit.evaluationMedium).setValidators([CustomValidators.required, CustomValidators.validateIsEmpty]);
    this.showTestFields = true;
    this.updateTestRelatedFormFields(true);
  }

  private masterLibraryAuditRuleInitializer(): void {
    this.subscription.add(
      this.auditRuleService.getRuleIds().subscribe((ruleIds: string[]): void => {
        this.existingRuleIds = ruleIds;
        this.form
          .get($auditRule.ruleId)
          .setValidators([CustomValidators.validateIsEmpty, CustomValidators.ruleIdDuplicatedValidator(this.existingRuleIds)]);
        this.form.get($auditRule.ruleId).updateValueAndValidity();
      }),
    );

    this.subscription.add(
      this.activatedRoute.queryParams.subscribe((params: Params): void => {
        if (SharedCommonUtility.notNullish(params.ruleLibrary)) {
          if (this.currentLibrary !== params.ruleLibrary) {
            this.currentLibrary = params.ruleLibrary;
            this.form.get($auditRule.ruleLibrary).setValue(params.ruleLibrary);
          }

          const ruleId: string = params.ruleId ?? this.activatedRoute.snapshot.paramMap.get('ruleId');
          this.form.get($auditRule.ruleId).setValue(ruleId);
          if (params.ruleId) {
            this.form.get($auditRule.ruleId).markAsTouched();
          }

          this.form.get($auditRule.description).setValue(params.description);
          this.form.get($auditRule.severity).setValue(params.severity);

          if (params.ruleLibrary === DesignRuleLibrary.design) {
            this.router
              .navigate([`../${Api.createDesignRule}`], {
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'preserve',
                skipLocationChange: true,
              })
              .then();
          }
        }
      }),
    );

    this.startPropagatingFormChangesToQueryParams();
  }

  private buildSeveritiesObservable(): void {
    this.severities$ = this.featureFlagService.variation$(FeatureFlagCollection.customSeverities, false).pipe(
      switchMap((customSeveritiesEnabled: boolean): Observable<INameValue[]> => {
        if (!customSeveritiesEnabled || !this.isCustomAuditRule) {
          let severityKeys: $severity[];

          if (this.isCustomAuditRule) {
            severityKeys = Object.values($severity).filter(
              (severity: $severity) => !TenantSeveritiesService.isCustomSeverity(severity),
            );
          } else {
            // Preserve info severity for audit form
            severityKeys = Object.values($severity).filter((severity: $severity) => !customSeverities.includes(severity));
          }

          return of(
            severityKeys.map((severity: $severity) => ({
              name: `scan_issue_severity_${severity}`,
              value: severity,
            })),
          );
        }

        return this.tenantSeveritiesService.getAll().pipe(
          map((customSeveritiesMap: ICustomSeveritiesMap): INameValue[] => {
            return this.tenantSeveritiesService
              .getSeverityKeysSortedByRank(customSeveritiesMap)
              .map((severity: $severity): INameValue | null => {
                const customSeverity: ICustomSeverity = customSeveritiesMap.get(severity);

                if (SharedCommonUtility.notNullish(customSeverity)) {
                  return {
                    name: customSeverity.label,
                    value: severity,
                  };
                }

                return null;
              })
              .filter((nameValue: INameValue) => SharedCommonUtility.notNullish(nameValue));
          }),
        );
      }),
    );
  }

  private getFormattedStandardsByIdentifiers(identifiers: string[]): string[] {
    if (SharedCommonUtility.isNullishOrEmpty(identifiers)) {
      return [];
    }

    const formattedStandards: string[] = [];
    const supportedStandards: AuditStandards[] = [AuditStandards.wcag, AuditStandards.en301549, AuditStandards.section508];

    for (const standard of supportedStandards) {
      const successCriteria: ISuccessCriteria[] = this.successCriteriaService.getSuccessCriteriaListFromStandards(identifiers, [
        standard,
      ]);
      if (SharedCommonUtility.notNullishOrEmpty(successCriteria)) {
        const levelByVersions: Record<string, string[]> = successCriteria.reduce(
          (acc: Record<string, string[]>, current: ISuccessCriteria): Record<string, string[]> => {
            current.versions.forEach((version: string): void => {
              if (current[$successCriteria.level]) {
                acc[version] = [...new Set([...(acc[version] ?? []), current[$successCriteria.level]])];
              }
            });
            return acc;
          },
          {},
        );
        const versions: string[] = Object.keys(levelByVersions);
        if (versions.length > 0) {
          versions.forEach((version: string) => {
            formattedStandards.push(`${standard} ${version} ${levelByVersions[version].join(', ')}`);
          });
        } else {
          formattedStandards.push(standard);
        }
      }
    }

    return formattedStandards;
  }

  public successCriteriaToLabel(sc: ISuccessCriteria): string {
    return `${sc[$successCriteria.num]} ${sc[$successCriteria.handle]}`;
  }

  public successCriteriaToSelectedItem(sc: ISuccessCriteria): string {
    return sc[$successCriteria.num];
  }

  public findSuccessCriteriaToSelectedItem(sc: ISuccessCriteria): string {
    return sc[$successCriteria.num];
  }

  public onSave(): void {
    this.formValidationRequest$.next();

    if (this.form.valid === false) {
      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return undefined;
    }

    let ruleUpsertRequest: AuditRuleUpsertRequest;

    if (this.action === AuditRuleAction.Create) {
      ruleUpsertRequest = omit(this.form.value, $manualAudit.evaluationMedium) as Required<typeof this.form.value> & {
        [$auditRule.successCriterias]: string[];
        [$auditRule.techniques]?: string[];
      };
    } else {
      ruleUpsertRequest = this.isCustomAuditRule
        ? (omit(
            this.form.value,
            $auditRule.ruleId,
            $auditRule.techniques,
            $manualAudit.evaluationMedium,
          ) as IEditCustomAuditRules)
        : (omit(
            this.form.value,
            $auditRule.ruleId,
            $auditRule.techniques,
            $manualAudit.evaluationMedium,
          ) as IAuditRuleEditRequest);
    }

    if (!this.showTestFields) {
      ruleUpsertRequest[$auditRule.testId] = null;
      ruleUpsertRequest[$auditRule.testName] = null;
      ruleUpsertRequest[$auditRule.testManualSteps] = null;
    }

    ruleUpsertRequest[$auditRule.techniques] = SharedCommonUtility.notNullishOrEmpty(this.form.value.techniques)
      ? this.form.value.techniques.split('\n').filter(SharedCommonUtility.notNullishOrEmpty)
      : [];

    return this.save.emit({
      action: this.action,
      ruleUpsertRequest,
    });
  }

  public get cancelLink(): string[] {
    return this.isCustomAuditRule ? ['/', Api.custom_audit_rules] : ['/', Api.admin, Api.master_library];
  }

  public get librarySelectContext(): SelectFieldContext {
    if (this.isCustomAuditRule) {
      return {
        field: $manualAudit.evaluationMedium,
        label: 'testing_medium',
        translateName: true,
      };
    }

    return {
      field: $auditRule.ruleLibrary,
      label: 'library',
      valueKey: 'value',
      nameKey: 'name',
    };
  }

  public ngAfterViewInit(): void {
    this.form.get($auditRule.ruleLibrary).updateValueAndValidity();
  }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit(): void {
    this.initializeLibrariesObservables();
    this.buildSeveritiesObservable();

    if (!this.isCustomAuditRule) {
      this.masterLibraryAuditRuleInitializer();
    } else {
      this.customAuditRuleInitializer();
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
