import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DsButtonVariants, DsTooltipPosition, Icons } from '@levelaccess/design-system';
import { BaseCellComponent, ICellConfig } from '../base-cell/base-cell.component';

export interface ITooltipCellConfig extends ICellConfig {
  tooltipPlacement?: DsTooltipPosition;
  icon?: Icons;
}

const defaultConfig: ITooltipCellConfig = {
  tooltipPlacement: DsTooltipPosition.right,
  icon: Icons.CircleInfo,
};

@Component({
  selector: 'table-cell-tooltip',
  styleUrls: ['../base-cell/base-cell.component.scss'],

  template: `
    <button
      #tooltipRef
      ds-button
      [variant]="DsButtonVariants.microAction"
      data-test-id="table-cell-tooltip"
      [microActionIcon]="config.icon"
      [ds-tooltip]="config.translate ? (config.text | translate) : config.text"
      [tooltipPlacement]="config.tooltipPlacement"
    ></button>
  `,
})
export class TooltipCellComponent extends BaseCellComponent implements OnInit {
  @ViewChild('tooltipRef', { read: ElementRef })
  public element: ElementRef<HTMLElement>;
  @Input() public config: ITooltipCellConfig;

  protected readonly DsTooltipPosition: typeof DsTooltipPosition = DsTooltipPosition;
  protected readonly DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  protected readonly Icons: typeof Icons = Icons;

  public ngOnInit(): void {
    this.config = { ...defaultConfig, ...this.config };
  }
}
