<div (window:resize)="onWindowResize()" class="digital-property-workspace-filter">
  <form class="d-flex flex-column align-items-start w-100" (ngSubmit)="applyFilter()">
    @if (useDsForm) {
      <div class="d-flex w-100 gap-2 align-items-end">
        <ds-input class="flex-1" [label]="'label_filter_by_workspace_digital_property_names' | translate">
          <input
            ds-input-field
            type="text"
            id="dpwSearchField"
            [(ngModel)]="filterText"
            [ngModelOptions]="{ standalone: true }"
            autocomplete="off"
          />
        </ds-input>
        <button
          id="dwpFilterButton"
          type="submit"
          ds-button
          [variant]="DsButtonVariants.secondary"
          class="dpw-filter-button pe-4 ps-4"
        >
          {{ 'form_filter' | translate }}
        </button>
      </div>
    } @else {
      <label for="dpwSearchField" class="label margin-bottom-xs">
        {{ 'label_filter_by_workspace_digital_property_names' | translate }}
      </label>
      <div class="d-flex w-100">
        <input
          type="text"
          id="dpwSearchField"
          [(ngModel)]="filterText"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control dpw-search-field"
        />
        <button type="submit" id="dwpFilterButton" class="btn btn-outline-primary margin-left pe-4 ps-4 fw-bold">
          {{ 'form_filter' | translate }}
        </button>
      </div>
    }
  </form>

  <div class="margin-top toggle-buttons" *ngIf="shouldShowExpandCollapseButtons">
    <button
      type="button"
      class="btn btn-link btn-flat fw-bold"
      [disabled]="areAllExpanded() ? true : null"
      (click)="expandAll($event)"
    >
      {{ 'label_expand_all' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-link btn-flat fw-bold"
      [disabled]="areAllCollapsed() ? true : null"
      (click)="collapseAll($event)"
    >
      {{ 'label_collapse_all' | translate }}
    </button>
  </div>

  <app-accordion [activeIds]="'accordion-panel-' + selectedWorkspaceIndex">
    <div appAccordionPanel *ngFor="let value of filteredValues; index as i" [id]="'accordion-panel-' + i">
      <ng-template appAccordionPanelHeader let-opened="opened">
        <button
          appAccordionPanelToggle
          appAccordionButton
          class="accordion-content"
          focusState="focus"
          hoverState="hover"
          [style.padding-left]="'1rem'"
          [style.margin]="'2px 2px 2px 0px'"
        >
          <app-accordion-chevron [opened]="opened"></app-accordion-chevron>
          <p [class.fw-bold]="i === selectedWorkspaceIndex" class="m-0 text-start accordion-text">
            {{ value.workspaceName }}
          </p>
        </button>
      </ng-template>
      <ng-template appAccordionPanelContent>
        <div *ngFor="let prop of value.digitalProperties">
          <button
            appAccordionButton
            focusState="focus"
            hoverState="hover"
            class="accordion-content"
            [class.fw-bold]="selectedDigitalPropertyId === prop._id"
            [attr.aria-pressed]="selectedDigitalPropertyId === prop._id"
            [style.padding-left]="'2.5rem'"
            (click)="select(prop._id)"
          >
            <p class="m-0 text-start accordion-text">
              {{ prop.name }}
            </p>
          </button>
        </div>
      </ng-template>
    </div>
  </app-accordion>
</div>
