<form [formGroup]="form">
  <div class="row">
    <div class="col-12 col-lg-8 col-lg-6">
      <div class="row">
        <div class="col-12">
          <h2>{{ 'rule_details' | translate }}</h2>
          {{ 'required_fields_label' | translate }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-common-input
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              disabled: action === AuditRuleAction.Update,
              label: 'issue_field_ruleId',
              field: $auditRule.ruleId,
            }"
          >
          </app-common-input>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_description',
              field: $auditRule.description,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-lg-6">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="isCustomAuditRule ? evaluationMediums : (libraries$ | async)"
            [context]="librarySelectContext"
          ></app-common-select>
        </div>
        <div class="col-12 col-lg-6">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="categories$ | async"
            [context]="{
              field: $auditRule.category,
              label: 'category',
            }"
          ></app-common-select>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-multiselect-typeahead
            [label]="isCustomAuditRule ? 'label_criteria' : 'issue_field_successCriteria'"
            [formControlName]="$auditRule.successCriterias"
            [possibleValues]="successCriterias"
            [valueToDropdownLabelFunction]="successCriteriaToLabel"
            [valueToSelectedItemLabelFunction]="successCriteriaToSelectedItem"
            [valueToOutputValueFunction]="findSuccessCriteriaToSelectedItem"
            [required]="true"
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
          ></app-multiselect-typeahead>
        </div>
      </div>
      <div *ngIf="isCustomAuditRule" class="row" >
        <div class="label">{{ 'accessibility_standards' | translate }}</div>
        <ng-container *ngIf="accessibilityStandards$ | async as standards; else placeholder">
          <ng-container *ngIf="standards.length > 0; else placeholder">
            <div *ngFor="let standard of standards">
              <span class="content">{{ standard }}</span>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #placeholder>
          <span class="content">{{ 'accessibility_standards_placeholder' | translate }}</span>
        </ng-template>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="severities$ | async"
            [context]="{
              field: $auditRule.severity,
              label: 'audit_rule_property_severity',
              translateName: true,
              valueKey: 'value',
              nameKey: 'name',
            }"
          ></app-common-select>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_stepsToReproduce',
              field: $auditRule.stepsToReproduce,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_actualResult',
              field: $auditRule.actualResult,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-textarea
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [context]="{
              label: 'issue_field_recommendation',
              field: $auditRule.recommendation,
              placeholder: '',
              required: true,
            }"
          >
          </app-common-textarea>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="functionalImpacts"
            [context]="{
              field: $auditRule.functionalImpact,
              label: 'issue_field_functionalImpact',
              valueKey: 'value',
              translateName: true,
              nameKey: 'name',
            }"
          ></app-common-select>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <app-common-checkbox-select
            [form]="form"
            [formValidationRequest$]="formValidationRequest$"
            [values]="disabilities"
            [context]="{
              field: $auditRule.disabilitiesAffected,
              label: 'issue_field_disabilityAffected',
              valueKey: 'value',
              translateName: true,
              nameKey: 'name',
            }"
          ></app-common-checkbox-select>
        </div>
      </div>

      <ng-container *ngIf="showTestFields">
        <div class="row mt-4">
          <div class="col-12">
            <app-common-input
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'audit_rule_property_test_id',
                field: $auditRule.testId,
              }"
            >
            </app-common-input>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <app-common-input
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'audit_rule_property_test_name',
                field: $auditRule.testName,
              }"
            >
            </app-common-input>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <app-common-textarea
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'audit_rule_property_test_manual_steps',
                field: $auditRule.testManualSteps,
                placeholder: '',
                required: true,
              }"
            >
            </app-common-textarea>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isWebLaLibrary || isCustomAuditRule">
        <div class="row mt-4">
          <div class="col-12">
            <app-common-textarea
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'label_compliant_code_example',
                field: $auditRule.compliantCodeExample,
                placeholder: '',
                required: false,
              }"
            >
            </app-common-textarea>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <app-common-textarea
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'label_non-compliant_code_example',
                field: $auditRule.nonCompliantCodeExample,
                placeholder: '',
                required: false,
              }"
            >
            </app-common-textarea>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <app-common-textarea
              [form]="form"
              [formValidationRequest$]="formValidationRequest$"
              [context]="{
                label: 'audit_rule_property_techniques',
                field: $auditRule.techniques,
                placeholder: '',
                required: false,
              }"
            >
            </app-common-textarea>
          </div>
        </div>
      </ng-container>

      <div class="row mt-4">
        <div class="d-flex align-items-center">
          <button
            class="me-3"
            ds-button
            [variant]="DsButtonVariants.primary"
            
            (click)="onSave()"
          >
            {{ 'form_save' | translate }}
          </button>

          <a ds-button [variant]="DsButtonVariants.secondary" [routerLink]="cancelLink" queryParamsHandling="merge">
            {{ 'form_cancel' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
