import { Component, Input } from '@angular/core';
import { $severity } from '../../../../../../../shared/constants/accessibility';
import { ICellConfig } from '../base-cell/base-cell.component';
import { BaseCellComponent } from '../base-cell/base-cell.component';

export interface ISeverityCellConfig extends ICellConfig {
  severity: $severity;
  text?: string;
}

@Component({
  selector: 'table-cell-severity',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div #ref class="severity-block severity" [ngClass]="'severity-' + config.severity">
      <ng-container *ngIf="config.severity === $severity.critical; else severityNotCritical">
        <svg aria-hidden="true" height="1rem" width="1rem">
          <use xlink:href="#exclamation-triangle"></use>
        </svg>
      </ng-container>

      <ng-template #severityNotCritical>
        <svg aria-hidden="true" height="1rem" width="1rem">
          <use xlink:href="#exclamation-circle"></use>
        </svg>
      </ng-template>
      {{ (config.text | titlecase) || ('scan_issue_severity_' + config.severity | translate) }}
    </div>
  `,
})
export class SeverityCellComponent extends BaseCellComponent {
  @Input() public config: ISeverityCellConfig;
  public $severity: typeof $severity = $severity;
}
