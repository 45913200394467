import { EssentialVersion, WcagLevel, WcagVersion } from '../interfaces/audit-rule.interface';
import { standardNameAndLevel } from './scanning';
import { AuditStandards } from './audit-standard';
import { EvaluationStandards } from './manual-evaluation';
import { IParsedStandards } from '../interfaces/standard-parsing.interface';

export const ParsedStandards: IParsedStandards = Object.freeze({
  [standardNameAndLevel.WCAG_v20_A]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v20,
    levels: [WcagLevel.A],
  },
  [standardNameAndLevel.WCAG_v20_AA]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v20,
    levels: [WcagLevel.A, WcagLevel.AA],
  },
  [standardNameAndLevel.WCAG_v20_AAA]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v20,
    levels: [WcagLevel.A, WcagLevel.AA, WcagLevel.AAA],
  },
  [standardNameAndLevel.WCAG_v21_A]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v21,
    levels: [WcagLevel.A],
  },
  [standardNameAndLevel.WCAG_v21_AA]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v21,
    levels: [WcagLevel.A, WcagLevel.AA],
  },
  [standardNameAndLevel.WCAG_v21_AAA]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v21,
    levels: [WcagLevel.A, WcagLevel.AA, WcagLevel.AAA],
  },
  [standardNameAndLevel.WCAG_v22_A]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v22,
    levels: [WcagLevel.A],
  },
  [standardNameAndLevel.WCAG_v22_AA]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v22,
    levels: [WcagLevel.A, WcagLevel.AA],
  },
  [standardNameAndLevel.WCAG_v22_AAA]: {
    standard: AuditStandards.wcag,
    version: WcagVersion.v22,
    levels: [WcagLevel.A, WcagLevel.AA, WcagLevel.AAA],
  },
  [standardNameAndLevel.essential_v10_A]: {
    standard: AuditStandards.essential,
    version: EssentialVersion.v10,
    levels: [WcagLevel.A],
  },
  [EvaluationStandards.en301549]: {
    standard: AuditStandards.en301549,
    version: null,
    levels: [],
  },
  [EvaluationStandards.section508]: {
    standard: AuditStandards.section508,
    version: null,
    levels: [],
  },
});
