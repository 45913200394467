import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { snakeCase } from 'lodash';
import { Icons } from '@levelaccess/design-system';
import { TranslateService } from '../../../../translate/translate.service';
import { $riskScoreLevels } from '../../../../../../shared/constants/risk-metrics';
import { DateUtility } from '../../../../utility/date.utility';
import { SharedCommonUtility } from '../../../../../../shared/utils/common.utility';
import { ICustomSeveritiesMap } from '../../../../../../shared/interfaces/tenant.interface';
import { $severity } from '../../../../../../shared/constants/accessibility';
import { $customSeverity } from '../../../../../../shared/constants/tenant';

export interface IFindingsBreakdownData {
  resolvedCount: number;
  criticalOverdueCount: number;
  highOverdueCount: number;
  custom1OverdueCount?: number;
  custom2OverdueCount?: number;
  lowOverdueCount: number;
  computedDate: Date;
}

@Component({
  selector: 'app-risk-score-explanation-modal',
  templateUrl: './risk-score-explanation-modal.component.html',
  styleUrls: ['./risk-score-explanation-modal.component.scss'],
})
export class RiskScoreExplanationModalComponent implements OnInit, OnChanges {
  @Input() public level: $riskScoreLevels;
  @Input() public additionalExplanation: string;
  @Input() public findingsData: IFindingsBreakdownData;
  @Input() public levelToExplanation: Record<$riskScoreLevels, string>;
  @Input() public customSeveritiesData: ICustomSeveritiesMap;
  @Input() public customSeverityKeysSortedByRank: $severity[];

  public Icons: typeof Icons;
  public findingsBreakdown: string[];

  constructor(
    public modal: NgbActiveModal,
    private translateService: TranslateService,
  ) {
    this.Icons = Icons;
  }

  public get availableLevels(): string[] {
    return Object.values($riskScoreLevels).map(this.translateLevel.bind(this));
  }

  public get currentLevelText(): string {
    return this.translateLevel(this.level);
  }

  public get levelExplanation(): string {
    const localization: string = SharedCommonUtility.isNullish(this.levelToExplanation)
      ? this.defaultLevelExplanationPerLevel[this.level]
      : this.levelToExplanation[this.level];
    return this.translateService.instant(localization);
  }

  public get formattedComputedDate(): string {
    return DateUtility.prettifyDate(this.findingsData.computedDate, {
      month: 'long',
      day: 'numeric',
    });
  }

  private buildFindingsBreakdown(): string[] {
    if (SharedCommonUtility.isNullish(this.findingsData)) {
      return null;
    }

    if (SharedCommonUtility.isNullish(this.customSeveritiesData)) {
      return [
        this.translateService.instant('findings_breakdown_resolved', this.findingsData.resolvedCount.toLocaleString()),
        this.translateService.instant(
          'findings_breakdown_critical_overdue',
          this.findingsData.criticalOverdueCount.toLocaleString(),
        ),
        this.translateService.instant('findings_breakdown_high_overdue', this.findingsData.highOverdueCount.toLocaleString()),
        this.translateService.instant('findings_breakdown_low_overdue', this.findingsData.lowOverdueCount.toLocaleString()),
      ];
    }

    const translateFinding = (severity: $severity, count: number): string => {
      const severityLabel: string = this.customSeveritiesData.get(severity)?.[$customSeverity.label]?.toLocaleLowerCase();
      return this.translateService.instant('findings_breakdown_x_overdue', [severityLabel, count.toLocaleString()]);
    };

    const availableFindings: string[] = [
      this.translateService.instant('findings_breakdown_resolved', this.findingsData.resolvedCount.toLocaleString()),
      translateFinding($severity.critical, this.findingsData.criticalOverdueCount),
      translateFinding($severity.high, this.findingsData.highOverdueCount),
    ];

    if (SharedCommonUtility.notNullishOrEmpty(this.customSeverityKeysSortedByRank)) {
      this.customSeverityKeysSortedByRank.forEach((customSeverity: $severity) => {
        if (customSeverity === $severity.custom1) {
          availableFindings.push(translateFinding($severity.custom1, this.findingsData.custom1OverdueCount));
        }
        if (customSeverity === $severity.custom2) {
          availableFindings.push(translateFinding($severity.custom2, this.findingsData.custom2OverdueCount));
        }
      });
    }

    availableFindings.push(translateFinding($severity.low, this.findingsData.lowOverdueCount));
    return availableFindings;
  }

  private translateLevel(level: $riskScoreLevels): string {
    return this.translateService.instant(snakeCase(level));
  }

  private get defaultLevelExplanationPerLevel(): Record<$riskScoreLevels, string> {
    return {
      [$riskScoreLevels.behind]: 'risk_score_level_explanation_for_behind',
      [$riskScoreLevels.needsAttention]: 'risk_score_level_explanation_for_needs_attention',
      [$riskScoreLevels.onTrack]: 'risk_score_level_explanation_for_on_track',
      [$riskScoreLevels.excelling]: 'risk_score_level_explanation_for_excelling',
    };
  }

  public ngOnInit(): void {
    this.findingsBreakdown = this.buildFindingsBreakdown();
  }

  public ngOnChanges(): void {
    this.findingsBreakdown = this.buildFindingsBreakdown();
  }
}
