import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { RestService, AuditRulesParams } from './rest.service';
import { UserPropertyService } from './user-property.service';
import { IAuditRule, IAuditRuleLibrary, IAuditRuleServerResponse } from '../../../shared/interfaces/audit-rule.interface';
import { ICurrentSelectedProperty } from './user.service';
import { $sortingOrder } from '../../../shared/constants/sort';
import { IAuditDbRule } from '../../../shared/interfaces/audit-rule.db.interface';
import { IAuditRuleCreateRequest, IAuditRuleEditRequest } from '../../../shared/interfaces/audit-rule.request.interface';
import { IToggleRuleRequest } from '../../../shared/interfaces/ignore.interface';
import { IAuditStandard } from '../../../shared/interfaces/audit-standard.interface';

@Injectable({
  providedIn: 'root',
})
export class AuditRuleService {
  constructor(
    private restService: RestService,
    private userService: UserPropertyService,
  ) {}

  public findCurrentDigitalPropertyAuditRules(
    limit: number = 50,
    skip: number = 0,
    filters: { [key: string]: string[] } = {},
    excludeHistory: boolean = false,
    sortBy: string = null,
    sortOrder: $sortingOrder = null,
  ): Observable<IAuditRuleServerResponse> {
    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) =>
          this.restService.findDigitalPropertyAuditRules(
            workspaceId,
            digitalPropertyId,
            limit,
            skip,
            filters,
            excludeHistory,
            sortBy,
            sortOrder,
          ),
        ),
      );
  }

  public findAuditRules(options?: AuditRulesParams): Observable<IAuditRuleServerResponse> {
    return this.restService.findAuditRules(options);
  }

  public toggleAuditRule(ruleId: string, enable: boolean, reason: string, isBulkOperation?: boolean): Observable<IAuditRule> {
    const request: IToggleRuleRequest = {
      ruleId,
      enable,
      reason,
      isBulkOperation,
    };

    return this.userService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) =>
          this.restService.toggleRule(workspaceId, digitalPropertyId, request),
        ),
      );
  }

  public getLibraries(): Observable<IAuditRuleLibrary[]> {
    return this.restService.getAuditRuleLibraries().pipe(
      map((libraries: IAuditRuleLibrary[]) => {
        return [...libraries].sort((a: IAuditRuleLibrary, b: IAuditRuleLibrary) => a.name.localeCompare(b.name));
      }),
    );
  }

  public findRuleByID(ruleId: string): Observable<IAuditRule> {
    return this.restService.findRuleByID(ruleId);
  }

  public createAuditRule(ruleCreationRequest: IAuditRuleCreateRequest): Observable<IAuditDbRule> {
    return this.restService.createAuditRule(ruleCreationRequest);
  }

  public editRuleByRuleId(ruleEditionRequest: IAuditRuleEditRequest, ruleId: string): Observable<boolean> {
    return this.restService.editRuleById(ruleEditionRequest, ruleId);
  }

  public getRuleIds(): Observable<string[]> {
    return this.restService.ruleIds();
  }

  public deleteRule(ruleId: string): Observable<IAuditRule> {
    return this.restService.deleteRule(ruleId);
  }

  public getStandardsForLibrary(libraryCode: string): Observable<IAuditStandard[]> {
    return this.restService.getStandardsForLibrary(libraryCode);
  }
}
