import { Component, Input } from '@angular/core';
import { DsColors, Icons, IconStyles } from '@levelaccess/design-system';

import { $severity } from '../../../../shared/constants/accessibility';
import { ICustomSeveritiesMap, ICustomSeverity } from '../../../../shared/interfaces/tenant.interface';
import { $customSeverity } from '../../../../shared/constants/tenant';
import { SeverityClass } from '../../interfaces/custom-severities.interface';

@Component({
  selector: 'app-custom-rule-severity',
  templateUrl: './custom-rule-severity.component.html',
  styleUrls: ['./custom-rule-severity.component.scss'],
})
export class CustomRuleSeverityComponent {
  protected readonly Icons: typeof Icons = Icons;
  protected readonly IconStyles: typeof IconStyles = IconStyles;
  protected readonly colors: typeof DsColors = DsColors;

  @Input() public severity: $severity;
  @Input() public customSeverities: ICustomSeveritiesMap;

  public $severity: typeof $severity;
  constructor() {
    this.$severity = $severity;
  }

  protected get severityText(): string {
    return this.customSeverities?.get(this.severity)?.[$customSeverity.label];
  }

  protected get severityIcon(): Icons {
    const customSeverity: ICustomSeverity = this.customSeverities.get(this.severity);
    if (customSeverity[$customSeverity.system]) {
      return this.systemSeverityIcon;
    }
    if (customSeverity[$customSeverity.rank] === this.customSeverities.get($severity.high)[$customSeverity.rank] - 1) {
      return Icons.ChevronUp;
    }
    return Icons.ChevronDown;
  }

  protected get severityClass(): SeverityClass {
    const customSeverity: ICustomSeverity = this.customSeverities.get(this.severity);
    if (customSeverity[$customSeverity.system]) {
      return this.systemSeverityClass;
    }
    if (customSeverity[$customSeverity.rank] === this.customSeverities.get($severity.high)[$customSeverity.rank] - 1) {
      return SeverityClass.moderate;
    }
    return SeverityClass.minor;
  }

  private get systemSeverityIcon(): Icons | undefined {
    switch (this.severity) {
      case $severity.critical:
        return Icons.CircleMinus;
      case $severity.high:
        return Icons.ChevronsUp;
      case $severity.low:
        return Icons.ChevronsDown;
      default:
        return undefined;
    }
  }

  protected get systemSeverityClass(): SeverityClass | undefined {
    switch (this.severity) {
      case $severity.critical:
        return SeverityClass.critical;
      case $severity.high:
        return SeverityClass.high;
      case $severity.low:
        return SeverityClass.low;
      default:
        return undefined;
    }
  }
}
