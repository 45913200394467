import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Icons } from '@levelaccess/design-system';

import { TenantSeveritiesApi } from './rest/tenant-severities.api';
import {
  IUpsertCustomSeverityRequest,
  ISwapCustomSeveritiesRanksRequest,
  ICustomSeveritiesMap,
  ICustomSeveritiesObj,
  ICustomSeverity,
  ICustomSeverityWithSeverityKey,
} from '../../../shared/interfaces/tenant.interface';
import { $severity, CustomSeverityKeyType, systemSeverities } from '../../../shared/constants/accessibility';
import { $customSeverity } from '../../../shared/constants/tenant';
import { SeverityClass } from '../interfaces/custom-severities.interface';

export interface ISeveritiesClassAndIcon {
  class: SeverityClass;
  icon: Icons;
}

export type ISeveritiesClassAndIconMap = Map<$severity, ISeveritiesClassAndIcon>;

@Injectable({
  providedIn: 'root',
})
export class TenantSeveritiesService {
  private readonly systemSeverityClassAndIconByKeys: ISeveritiesClassAndIconMap = new Map([
    [$severity.critical, { class: SeverityClass.critical, icon: Icons.CircleMinus }],
    [$severity.high, { class: SeverityClass.high, icon: Icons.ChevronsUp }],
    [$severity.low, { class: SeverityClass.low, icon: Icons.ChevronsDown }],
  ]);
  private readonly customSeverityClassAndIconSortedByRank: ISeveritiesClassAndIcon[] = [
    { class: SeverityClass.moderate, icon: Icons.ChevronUp },
    { class: SeverityClass.minor, icon: Icons.ChevronDown },
  ];

  public constructor(private tenantSeveritiesApi: TenantSeveritiesApi) {}

  public getSeverityKeysSortedByRank(severitiesMap: ICustomSeveritiesMap): $severity[] {
    const severityArray: ICustomSeverityWithSeverityKey[] = [];

    severitiesMap.forEach((value: ICustomSeverity, key: $severity): void => {
      severityArray.push({ ...value, severityKey: key });
    });

    severityArray.sort(
      (a: ICustomSeverityWithSeverityKey, b: ICustomSeverityWithSeverityKey): number =>
        b[$customSeverity.rank] - a[$customSeverity.rank],
    );

    return severityArray.map((item: ICustomSeverityWithSeverityKey) => item.severityKey);
  }

  public getCustomSeverityKeysSortedByRank(severitiesMap: ICustomSeveritiesMap): $severity[] {
    return this.getSeverityKeysSortedByRank(severitiesMap).filter(TenantSeveritiesService.isCustomSeverity);
  }

  public getSeverityClassAndIconByKeys(severitiesMap: ICustomSeveritiesMap): ISeveritiesClassAndIconMap {
    const customSeverityKeysSortedByRank: $severity[] = this.getCustomSeverityKeysSortedByRank(severitiesMap);
    const severityClassAndIconByKeys: ISeveritiesClassAndIconMap = new Map(this.systemSeverityClassAndIconByKeys);

    for (const [idx, customSeverity] of customSeverityKeysSortedByRank.entries()) {
      severityClassAndIconByKeys.set(customSeverity, this.customSeverityClassAndIconSortedByRank[idx]);
    }

    return severityClassAndIconByKeys;
  }

  public getAll(): Observable<ICustomSeveritiesMap> {
    return this.tenantSeveritiesApi
      .getAll()
      .pipe(map((customSeverities: ICustomSeveritiesObj) => new Map(Object.entries(customSeverities)) as ICustomSeveritiesMap));
  }

  public create(createSeveritiesData: IUpsertCustomSeverityRequest): Observable<ICustomSeverity> {
    return this.tenantSeveritiesApi.create(createSeveritiesData);
  }

  public update(severityKey: $severity, updateSeveritiesData: IUpsertCustomSeverityRequest): Observable<ICustomSeverity> {
    return this.tenantSeveritiesApi.update(severityKey, updateSeveritiesData);
  }

  public swapRanks(updateSeveritiesData: ISwapCustomSeveritiesRanksRequest): Observable<void> {
    return this.tenantSeveritiesApi.swapRanks(updateSeveritiesData);
  }

  public deleteAndReassignSeverity(toDelete: CustomSeverityKeyType, reassignTo: $severity): Observable<void> {
    return this.tenantSeveritiesApi.deleteAndReassignSeverity(toDelete, reassignTo);
  }

  public static isCustomSeverity(severity: $severity): boolean {
    return !systemSeverities.includes(severity);
  }
}
