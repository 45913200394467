import { SharedCommonUtility } from './common.utility';
import { IParsedStandard } from '../interfaces/standard-parsing.interface';
import { ParsedStandards } from '../constants/standard-parsing';
import { IAuditStandard } from '../interfaces/audit-standard.interface';
import { $auditStandard, mapAuditDbStandardToAuditStandard } from '../constants/audit-standard';
import { IPopulatedManualFindingStandard } from '../interfaces/manual-audit-finding.interface';

export class SharedAuditStandardsUtility {
  public static filterStandards<AuditStandard extends IAuditStandard | IPopulatedManualFindingStandard>(
    auditStandards: AuditStandard[],
    nameVersionLevel: string,
  ): AuditStandard[] {
    const { standard, version, levels }: IParsedStandard = ParsedStandards[nameVersionLevel] ?? { standard: nameVersionLevel };

    const compareStandard = (value: AuditStandard): boolean =>
      mapAuditDbStandardToAuditStandard[value[$auditStandard.standard]] === standard;
    const compareVersion = (value: AuditStandard): boolean => value[$auditStandard.version] === version;
    const compareLevel = (value: AuditStandard): boolean => levels.includes(value[$auditStandard.level]);

    if (SharedCommonUtility.isNullish(version) && SharedCommonUtility.isNullishOrEmpty(levels)) {
      return auditStandards.filter(compareStandard);
    }

    if (SharedCommonUtility.isNullish(version)) {
      return auditStandards.filter((value: AuditStandard): boolean => compareStandard(value) && compareLevel(value));
    }

    return auditStandards.filter(
      (value: AuditStandard): boolean => compareStandard(value) && compareLevel(value) && compareVersion(value),
    );
  }
}
