export enum $testGuide {
  _id = '_id',
  tenant = 'tenant',
  testCategories = 'testCategories',
  testName = 'testName',
  name = 'name',
  ruleIds = 'ruleIds',
  code = 'code',
  ruleLibrary = 'ruleLibrary',
  conformanceLevelIds = 'conformanceLevelIds',
  conformanceLevels = 'conformanceLevels',
}

export enum CategoryStatus {
  passed = 'passed',
  failed = 'failed',
  inProgress = 'inProgress',
  notTested = 'notTested',
  na = 'na',
}

export enum TestGuideCodeName {
  WCAG20 = 'WCAG20',
  WCAG21 = 'WCAG21',
  WCAG22 = 'WCAG22',
  Impact_test = 'Impact_test',
  WCAG20_EA = 'WCAG20_EA',
  WCAG21_EA = 'WCAG21_EA',
  WCAG22_EA = 'WCAG22_EA',
  IOS22_LA = 'IOS22_EA',
  Android22_LA = 'Android22_LA',
  Hardware_Section508 = 'HARDWARE_SECTION508',
  PV_WEB = 'PV_WEB',
  WEB_LA = 'WEB_LA',
}
