export enum $automatedUserFlow {
  _id = '_id',
  name = 'name',
  lowercaseName = 'lowercaseName',
  digitalPropertyId = 'digitalPropertyId',
  actions = 'actions',
  lastScanStatus = 'lastScanStatus',
  lastScanDate = 'lastScanDate',
  lastScanScore = 'lastScanScore',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  flowType = 'flowType',
}

export enum $userFlowAction {
  _id = '_id',
  type = 'type',
  value = 'value',
  password = 'password',
  scanPointId = 'scanPointId',
  scanPoint = 'scanPoint',
  cssSelector = 'cssSelector',
}

export enum $userFlowActionError {
  userFlowAction = 'userFlowAction',
  error = 'error',
}

export enum UserFlowActionType {
  OPEN = 'OPEN',
  CLICK = 'CLICK',
  TYPE = 'TYPE',
  WAIT_FOR_ELEMENT = 'WAIT_FOR_ELEMENT',
  SCAN_POINT = 'SCAN_POINT',
  PASSWORD = 'PASSWORD',
  DROPDOWN = 'DROPDOWN', // native <select> elements
  CHANGE_INPUT = 'CHANGE_INPUT', // supports both <input> and <select> elements
  SCROLL_BY_PIXEL = 'SCROLL_BY_PIXEL',
  KEYSTROKE = 'KEYSTROKE',
  SCROLL_TO_ELEMENT = 'SCROLL_TO_ELEMENT',
}

export enum $automatedUserFlows {
  userFlows = 'userFlows',
  _total = '_total',
}

export const AUTOMATED_USER_FLOW_CHAR_LIMITS: Readonly<Record<string, number>> = Object.freeze({
  [$automatedUserFlow.name]: 100,
});

export const ACTION_CHAR_LIMITS: Readonly<Partial<Record<UserFlowActionType, number>>> = Object.freeze({
  [UserFlowActionType.OPEN]: 500,
  [UserFlowActionType.CLICK]: 500,
  [UserFlowActionType.TYPE]: 500,
  [UserFlowActionType.WAIT_FOR_ELEMENT]: 500,
  [UserFlowActionType.DROPDOWN]: 500,
  [UserFlowActionType.PASSWORD]: 500,
  [UserFlowActionType.CHANGE_INPUT]: 500,
  [UserFlowActionType.KEYSTROKE]: 100,
  [UserFlowActionType.SCROLL_TO_ELEMENT]: 100,
});

export const userFlowActionTypes: UserFlowActionType[] = [
  UserFlowActionType.OPEN,
  UserFlowActionType.SCAN_POINT,
  UserFlowActionType.CLICK,
  UserFlowActionType.TYPE,
  UserFlowActionType.WAIT_FOR_ELEMENT,
  UserFlowActionType.DROPDOWN,
  UserFlowActionType.PASSWORD,
  UserFlowActionType.CHANGE_INPUT,
  UserFlowActionType.SCROLL_BY_PIXEL,
  UserFlowActionType.KEYSTROKE,
  UserFlowActionType.SCROLL_TO_ELEMENT,
];

export const USER_FLOW_CLICK_TIMEOUT: number = 30 * 1000;
export const USER_FLOW_OPEN_URL_TIMEOUT: number = 30 * 1000;
export const USER_FLOW_WAIT_ELEMENT_TIMEOUT: number = 60 * 1000;
export const USER_FLOW_NAVIGATION_TIMEOUT: number = 15 * 1000;
export const USER_FLOW_KEYSTROKE_INTERVAL: number = 50;
export const USER_FLOW_CLICK_DELAY: number = 50;

export enum AutomatedUserFlowErrorParam {
  DUPLICATE_SCAN = 'duplicate scan in the same flow',
}

export enum AutomatedFlowType {
  preScan = 'preScan',
  fullScan = 'fullScan',
}
