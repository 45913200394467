import { RuleTestType } from '../interfaces/access-engine-accessibility.interface';
import { IAnalyticsByRuleResultItem, IRuleStatusCount } from './../interfaces/analytics.interface';
import {
  IPageSummary,
  IRuleComponentMetrics,
  IRuleComponentSummaryMetrics,
  IRuleSummary,
  IScanSummary,
  ISummaryMetrics,
  IUserFlowScanPointSummary,
} from '../interfaces/scan-summaries.interface';
import { $severity, severityToComparable } from '../constants/accessibility';
import { $ruleSummary, $scanSummaries, SCORE_ADJUSTMENT_MULTIPLIER, ScoringAlgorithm } from '../constants/scan-summaries';
import { IScanAudit } from '../interfaces/scan-audit.interface';
import { IAuditRule, IAuditStandard } from '../interfaces/audit-rule.interface';
import { $scanAudit } from '../constants/scan-audit';
import { AccessibilityAuditToolNames, AuditToolNameToAuditType } from '../constants/audit-tool';
import { $auditRule } from '../constants/audit-rule';
import { ISeverityCount } from '../interfaces/analytics.interface';
import { $auditStandard, $scanAuditStandard, AuditDbStandard, AuditStandards } from '../constants/audit-standard';
import { IAudit } from '../interfaces/audit.interface';
import { SharedCommonUtility } from './common.utility';
import { IManualScan, IScan } from '../interfaces/scan.interface';
import { $scan, $scanScope } from '../constants/scan';
import { $auditIssuesView, auditIssuesViewStatus } from '../constants/audit-issues-view';
import { IAuditIssueViewData, IAuditIssueViewQueryParams } from '../interfaces/audit-issues-view.interface';
import { Api } from '../constants/api';
import { scanStatus } from '../constants/scanning';
import { SharedDateUtility } from './date.utility';
import { $analyticsByRuleResultItem } from '../constants/analytics';
import { $page } from '../constants/page';
import { EVALUATION_STANDARDS, EvaluationStandards } from '../constants/manual-evaluation';
import { $manualAudit } from '../constants/manual-audit';
import { IManualEvaluation } from '../interfaces/manual-audit.interface';
import { IPopulatedManualFindingSuccessCriteria } from '../interfaces/manual-audit-finding.interface';
import { $auditStandardSuccessCriteria } from '../constants/audit-standard-success-criteria';
import { SharedAuditStandardsUtility } from './audit-standards.utility';
import { IAuditStandard as IAuditStandardStandard } from '../interfaces/audit-standard.interface';

type ISummary = IScanSummary | IPageSummary | IUserFlowScanPointSummary;

export class SharedAuditsUtility {
  private static getScoreImpacts(
    metrics: ISummaryMetrics,
    algorithm: ScoringAlgorithm = ScoringAlgorithm.weightedAverage,
  ): Record<string, number> {
    let weightedAverageDivisor: number = 0;

    for (const [, value] of Object.entries(metrics)) {
      if (SharedCommonUtility.notNullish(value.applicableWeight)) {
        weightedAverageDivisor += value.applicableWeight;
      }
    }

    const rulesImpact: Record<string, number> = {};

    for (const [key, value] of Object.entries(metrics)) {
      if (value[$ruleSummary.errors] > 0 && value[$ruleSummary.applicableWeight] > 0) {
        const score: number = (value[$ruleSummary.applicableWeight] / weightedAverageDivisor) * 1000;
        if (algorithm === ScoringAlgorithm.adjustedWeightedAverage) {
          rulesImpact[key] = Math.round(score * SCORE_ADJUSTMENT_MULTIPLIER) / 10;
        } else {
          rulesImpact[key] = Math.round(score) / 10;
        }
      }
    }

    return rulesImpact;
  }

  public static hasWcag(standards: IAuditStandard[]): boolean {
    return standards.every(
      (standard: IAuditStandard) =>
        standard[$scanAuditStandard.id] === AuditStandards.wcag ||
        standard[$scanAuditStandard.id] === AuditStandards.notApplicable,
    );
  }

  public static getTotalIssuesCount(scanSummary: IScanSummary | null): number {
    if (SharedCommonUtility.isNullish(scanSummary?.[$scanSummaries.metrics])) {
      return 0;
    }

    return Object.values(scanSummary[$scanSummaries.metrics])
      .map((ruleSummary: IRuleSummary): number => ruleSummary[$ruleSummary.errors])
      .reduce((sum: number, b: number): number => sum + b, 0);
  }

  public static getAuditIssueViewStatus(issue: IAuditIssueViewData): auditIssuesViewStatus {
    return this.computeAuditIssuesViewStatus(
      issue[$auditIssuesView.severity],
      issue[$auditIssuesView.failures],
      issue[$auditIssuesView.ignores],
    );
  }

  public static getAuditIssueViewStatusForRuleResult(result: IAnalyticsByRuleResultItem): auditIssuesViewStatus {
    return this.computeAuditIssuesViewStatus(
      result[$analyticsByRuleResultItem.severity],
      result[$analyticsByRuleResultItem.ruleSummary][$ruleSummary.errors],
      result[$analyticsByRuleResultItem.ruleSummary][$ruleSummary.ignored],
    );
  }

  public static computeAuditIssuesViewStatus(severity: $severity, failures: number, ignores: number): auditIssuesViewStatus {
    const hasFailures: boolean = failures > 0 || ignores > 0;

    if (severity !== $severity.info && hasFailures) {
      return auditIssuesViewStatus.failed;
    } else if (severity === $severity.info && hasFailures) {
      return auditIssuesViewStatus.review;
    } else if (severity !== $severity.info && !hasFailures) {
      return auditIssuesViewStatus.passed;
    }
    return null;
  }

  public static hasAuditIssuesViewStatus(status: auditIssuesViewStatus, issue: IAuditIssueViewData): boolean {
    return this.checkIssuesViewStatus(
      status,
      issue[$auditIssuesView.severity],
      issue[$auditIssuesView.failures],
      issue[$auditIssuesView.ignores],
    );
  }

  private static checkIssuesViewStatus(
    status: auditIssuesViewStatus,
    severity: $severity,
    numFailures: number,
    numIgnored: number,
  ): boolean {
    const hasFailures: boolean = numFailures > 0 || numIgnored > 0;

    switch (status) {
      case auditIssuesViewStatus.failed:
        return severity !== $severity.info && hasFailures;
      case auditIssuesViewStatus.review:
        return severity === $severity.info && hasFailures;
      case auditIssuesViewStatus.passed:
        return severity !== $severity.info && !hasFailures;
      default:
        return false;
    }
  }

  public static getFilteredByStatus(
    issues: readonly IAuditIssueViewData[],
    status: auditIssuesViewStatus,
    sortFields: readonly (keyof IAuditIssueViewData)[],
  ): IAuditIssueViewData[] {
    return issues
      .filter((value: IAuditIssueViewData): boolean => this.hasAuditIssuesViewStatus(status, value))
      .sort((a: IAuditIssueViewData, b: IAuditIssueViewData): number => {
        const comparatorResults: number[] = [];

        for (const sortField of sortFields) {
          const aValue: any = a[sortField];
          const bValue: any = b[sortField];

          if (sortField === $auditIssuesView.severity) {
            comparatorResults.push(severityToComparable[aValue].localeCompare(severityToComparable[bValue]));
            continue;
          }

          if (typeof aValue === 'string') {
            comparatorResults.push(aValue.localeCompare(bValue));
            continue;
          }

          comparatorResults.push(aValue - bValue);
        }

        return comparatorResults.find((value: number): boolean => value !== 0);
      });
  }

  public static getTopByStatus(
    issues: readonly IAuditIssueViewData[],
    status: auditIssuesViewStatus,
    sortFields: readonly (keyof IAuditIssueViewData)[],
    topCount: number = 5,
  ): IAuditIssueViewData[] {
    return this.getFilteredByStatus(issues, status, sortFields).reverse().slice(0, topCount);
  }

  public static addBaseUrl(baseUrl: string, issues: IAuditIssueViewData[]): IAuditIssueViewData[] {
    for (const issue of issues) {
      issue[$auditIssuesView.link] = new URL(issue[$auditIssuesView.link], baseUrl).toString();
    }

    return issues;
  }

  public static getTotalPages(scan: IScan): number {
    return scan[$scan.totalScannedPages];
  }

  public static getScanHealth(scanSummary: IScanSummary | null, scan: IScan): number | null {
    const score: number | undefined = scanSummary?.[$scanSummaries.score];

    if (SharedCommonUtility.isNullish(score)) {
      return null;
    }

    if (SharedCommonUtility.isNullish(scanSummary?.[$scanSummaries.metrics]) && scan[$scan.status] !== scanStatus.completed) {
      return null;
    }

    return score;
  }

  public static getSeverityCount(
    tool: AccessibilityAuditToolNames,
    scanAudit: IScanAudit,
    metrics: ISummaryMetrics | null,
    displayCustomSeverity: boolean = false,
  ): ISeverityCount {
    let severityCount: ISeverityCount = {
      [$severity.critical]: 0,
      [$severity.high]: 0,
      [$severity.low]: 0,
      [$severity.info]: 0,
    };

    if (displayCustomSeverity) {
      severityCount = {
        ...severityCount,
        [$severity.custom1]: 0,
        [$severity.custom2]: 0,
      };
    }

    if (SharedCommonUtility.isNullish(metrics) || SharedCommonUtility.isNullish(scanAudit)) {
      return severityCount;
    }

    if (SharedCommonUtility.isNullish(scanAudit?.[$scanAudit.audits]?.[AuditToolNameToAuditType[tool]])) {
      return severityCount;
    }

    const auditForTool: IAudit = scanAudit[$scanAudit.audits][AuditToolNameToAuditType[tool]];

    Object.values(metrics).forEach(addSeverityCount);

    return severityCount;

    function addSeverityCount(ruleSummary: IRuleSummary): void {
      const ruleName: string = ruleSummary[$ruleSummary.ruleName];
      const ruleInfo: IAuditRule = auditForTool.rules[ruleName];

      if (SharedCommonUtility.isNullish(ruleInfo)) {
        return;
      }

      const severity: $severity = ruleInfo[$auditRule.severity];
      const numOfIssues: number = ruleSummary[$ruleSummary.errors];
      const triggered: boolean = ruleSummary[$ruleSummary.triggered];

      if (triggered && SharedAuditsUtility.hasWcag(ruleInfo[$auditRule.standards])) {
        severityCount[severity] += numOfIssues;
      }
    }
  }

  public static getRuleStatusCount(summary: ISummary | null, tool: AccessibilityAuditToolNames): IRuleStatusCount {
    const ruleStatusCount: IRuleStatusCount = {
      [auditIssuesViewStatus.failed]: 0,
      [auditIssuesViewStatus.review]: 0,
      [auditIssuesViewStatus.passed]: 0,
    };

    if (SharedCommonUtility.isNullish(summary)) {
      return ruleStatusCount;
    }

    const [issues]: IAuditIssueViewData[][] = SharedAuditsUtility.getAuditIssuesViewData(
      summary,
      tool,
      summary[$scanSummaries.scanId],
    );

    Object.values(issues).forEach(addIssueViewStatusCount);

    return ruleStatusCount;

    function addIssueViewStatusCount(issue: IAuditIssueViewData): void {
      const ruleStatus: auditIssuesViewStatus = SharedAuditsUtility.getAuditIssueViewStatus(issue);
      if (SharedCommonUtility.notNullish(ruleStatus)) {
        ruleStatusCount[ruleStatus] += 1;
      }
    }
  }

  public static isManualReviewStatus(issue: IAuditIssueViewData): boolean {
    return (
      issue[$auditIssuesView.testType] === RuleTestType.guided_automatic &&
      SharedAuditsUtility.hasAuditIssuesViewStatus(auditIssuesViewStatus.failed, issue)
    );
  }

  /**
   * Constructs path to the issues view on UI (does not contain the application host name)
   */
  public static constructAuditIssuesViewLink(
    summary: ISummary,
    scanId: string,
    tool: AccessibilityAuditToolNames,
    ruleName: string,
    flawPageId: string | undefined,
    componentId?: string,
    isMonitoring?: boolean,
    isMobile?: boolean,
  ): string {
    const hasFlawPageId: boolean = typeof flawPageId === 'string';
    const isUserFlowScanPointSummary: boolean = SharedCommonUtility.notNullish(summary[$scanSummaries.scanPoint]);
    const hasUrl: boolean = typeof summary[$scanSummaries.url] === 'string';

    if (isMobile) {
      return this.buildMobileScanResultLink(scanId, ruleName);
    } else if (hasFlawPageId) {
      return this.buildFlawPageScanResultLink(flawPageId, scanId, tool, ruleName);
    } else if (componentId && isMonitoring === true) {
      return this.buildComponentReportLinkForMonitoring(scanId, componentId, tool, ruleName, summary as IPageSummary);
    } else if (componentId) {
      return this.buildComponentReportLink(scanId, componentId, tool, ruleName);
    } else if (isUserFlowScanPointSummary) {
      return this.buildScanPointReportLink(scanId, summary as IUserFlowScanPointSummary, tool, ruleName);
    } else if (hasUrl) {
      return this.buildSingleReportLink(scanId, summary, tool, ruleName);
    }

    return this.buildAggregatedReportLink(scanId, tool, ruleName);
  }

  private static buildMobileScanResultLink(scanId: string, ruleName: string): string {
    return `/${Api.websitesAndApps}/${Api.mobile_scans}/${Api.scan_results}/${scanId}/${Api.mobile_app_report}/${Api.rules}/${ruleName}`;
  }

  private static buildFlawPageScanResultLink(
    flawPageId: string,
    scanId: string,
    tool: AccessibilityAuditToolNames,
    ruleName: string,
  ): string {
    const baseLink = `/${Api.monitoring}/${Api.pages}/${flawPageId}/${Api.scan_results}/${scanId}`;

    return `${baseLink}/${AuditToolNameToAuditType[tool]}/${ruleName}`;
  }

  private static buildComponentReportLinkForMonitoring(
    scanId: string,
    componentId: string,
    tool: AccessibilityAuditToolNames,
    ruleName: string,
    summary: IPageSummary,
  ): string {
    const baseLink: string = `/${Api.monitoring}/${Api.components}/${Api.component_report}`;
    const documentId: string = summary[$scanSummaries.documentId];
    return `${baseLink}/${AuditToolNameToAuditType[tool]}/${componentId}/${Api.page_summary}/${scanId}/${documentId}/${ruleName}`;
  }

  private static buildComponentReportLink(
    scanId: string,
    componentId: string,
    tool: AccessibilityAuditToolNames,
    ruleName: string,
  ): string {
    const baseLink = `/${Api.scans}/${Api.scan_results}/${scanId}`;

    return `${baseLink}/${Api.component_report}/${AuditToolNameToAuditType[tool]}/${componentId}/${ruleName}`;
  }

  private static buildScanPointReportLink(
    scanId: string,
    summary: IUserFlowScanPointSummary,
    tool: AccessibilityAuditToolNames,
    ruleName: string,
  ): string {
    const baseLink = `/${Api.scans}/${Api.scan_results}/${scanId}/${Api.scan_point_report}/${summary[$scanSummaries.documentId]}`;
    const scanPointId: string = summary[$scanSummaries.scanPoint][$page._id];

    return `${baseLink}/${Api.pages}/${scanPointId}/${AuditToolNameToAuditType[tool]}/${ruleName}`;
  }

  private static buildSingleReportLink(
    scanId: string,
    summary: ISummary,
    tool: AccessibilityAuditToolNames,
    ruleName: string,
  ): string {
    const baseLink = `/${Api.scans}/${Api.scan_results}/${scanId}`;

    return `${baseLink}/${Api.single_report}/${AuditToolNameToAuditType[tool]}/${summary[$scanSummaries.documentId]}/${ruleName}`;
  }

  private static buildAggregatedReportLink(scanId: string, tool: AccessibilityAuditToolNames, ruleName: string): string {
    const baseLink = `/${Api.scans}/${Api.scan_results}/${scanId}`;

    return `${baseLink}/${Api.aggregated_report}/${AuditToolNameToAuditType[tool]}/${ruleName}`;
  }

  public static getSummaryQueryParams(summary: ISummary, tool: string): IAuditIssueViewQueryParams {
    if (typeof summary[$scanSummaries.url] === 'string') {
      return { url: summary[$scanSummaries.url], toolName: tool };
    }

    return {};
  }

  /**
   * Returns {@link IAuditIssueViewData} for WCAG and Recommendation (eSSENTIAL) rules.
   *
   * The first item in the returned tuple is WCAG rules, the second one - Recommendation.
   *
   * @param summary scan or page summary
   * @param tool audit tool name
   * @param scanId scan id
   * @param addScoreData flag indicating if Score Impact column must be added
   * @param flawPageId flaw page id, defined if view data is for issues in a Flaw Page report instead of Scan Page report
   * @param componentId component, defined when viewing a component report
   * @param isMonitoring
   * @param isMobile as in the type of DP
   * @return tuple of [wcag rules, recommendation rules]
   */
  public static getAuditIssuesViewData(
    summary: ISummary | null,
    tool: AccessibilityAuditToolNames,
    scanId: string,
    addScoreData: boolean = false,
    flawPageId?: string,
    componentId?: string,
    isMonitoring?: boolean,
    isMobile?: boolean,
  ): [IAuditIssueViewData[], IAuditIssueViewData[]] {
    const data: IAuditIssueViewData[] = [];
    const recommendations: IAuditIssueViewData[] = [];

    if (SharedCommonUtility.isNullish(summary?.[$scanSummaries.metrics])) {
      return [data, recommendations];
    }

    const utility: typeof SharedAuditsUtility = SharedAuditsUtility;
    const scanAuditsObj: IScanAudit = summary[$scanSummaries.scanAuditsId] as IScanAudit;

    let scoreData: Record<string, number>;

    if (addScoreData) {
      scoreData = utility.getScoreImpacts(summary[$scanSummaries.metrics], summary[$scanSummaries.algorithm]);
    }

    for (const [ruleId, ruleSummary] of Object.entries(summary[$scanSummaries.metrics])) {
      if (!ruleSummary[$ruleSummary.triggered]) {
        continue;
      }

      const ruleName: string = ruleSummary[$ruleSummary.ruleName];
      const ruleInfo: IAuditRule = scanAuditsObj[$scanAudit.audits][AuditToolNameToAuditType[tool]].rules[ruleName];

      const ruleTitle: string = ruleInfo[$auditRule.title];
      const successCriteriaInfo: IAuditStandard[] = ruleInfo[$auditRule.standards];
      const successCriteriaIdentifier: string[] = ruleInfo[$auditRule.wcagCriteria];
      const severity: $severity = ruleInfo[$auditRule.severity];
      const severityNumber: number = ruleInfo[$auditRule.severityNumber];
      let failures: number = ruleSummary[$ruleSummary.errors];
      let passes: number = ruleSummary[$ruleSummary.passed];
      let ignores: number = ruleSummary[$ruleSummary.ignored];

      if (componentId) {
        const componentMetrics: IRuleComponentMetrics = ruleSummary[$ruleSummary.components]?.[componentId];
        if (!componentMetrics) {
          continue;
        }
        failures = componentMetrics[$ruleSummary.errors];
        ignores = componentMetrics[$ruleSummary.ignored] ?? 0;
        passes = 0;
      }

      const issue: IAuditIssueViewData = {
        [$auditIssuesView.ruleDescription]: ruleTitle,
        [$auditIssuesView.successCriteriaConformanceLevel]: successCriteriaInfo,
        [$auditIssuesView.successCriteriaIdentifier]: successCriteriaIdentifier,
        [$auditIssuesView.severity]: severity,
        [$auditIssuesView.severityNumber]: severityNumber,
        [$auditIssuesView.failures]: failures,
        [$auditIssuesView.passes]: passes,
        [$auditIssuesView.ignores]: ignores,
        [$auditIssuesView.link]: utility.constructAuditIssuesViewLink(
          summary,
          scanId,
          tool,
          ruleName,
          flawPageId,
          componentId,
          isMonitoring,
          isMobile,
        ),
        [$auditIssuesView.queryParams]: utility.getSummaryQueryParams(summary, tool),
      };

      if (ruleInfo[$auditRule.testType]) {
        issue[$auditIssuesView.testType] = ruleInfo[$auditRule.testType];
      }

      if (addScoreData) {
        issue[$auditIssuesView.scoreImpact] = scoreData[ruleId] ?? 0;
      }

      const hasWcag: boolean = utility.hasWcag(successCriteriaInfo);

      if (hasWcag) {
        data.push(issue);
      } else {
        recommendations.push(issue);
      }
    }

    return [data, recommendations];
  }

  public static getTotalComponentsWithFindings(scanSummary: IScanSummary): number {
    if (SharedCommonUtility.isNullish(scanSummary?.[$scanSummaries.metrics])) {
      return 0;
    }

    const componentSummaryMetrics: IRuleComponentSummaryMetrics[] = Object.values(scanSummary[$scanSummaries.metrics])
      .map((ruleSummary: IRuleSummary): IRuleComponentSummaryMetrics => ruleSummary[$ruleSummary.components])
      .filter((componentMetric: IRuleComponentSummaryMetrics) => SharedCommonUtility.notNullish(componentMetric));

    const componentIds: string[] = componentSummaryMetrics.flatMap((componentSummaryMetric: IRuleComponentSummaryMetrics) => {
      return Object.entries(componentSummaryMetric)
        .map(([componentId, componentMetrics]: [string, IRuleComponentMetrics]) => {
          if (componentMetrics[$ruleSummary.errors] > 0) {
            return componentId;
          }
          return null;
        })
        .filter((componentId: string) => SharedCommonUtility.notNullish(componentId));
    });

    return new Set(componentIds).size;
  }

  public static getNumberOfComponentFindingsByRule(scanSummary: IScanSummary): Record<string, number> {
    if (SharedCommonUtility.isNullish(scanSummary?.[$scanSummaries.metrics])) {
      return {};
    }

    const result: Record<string, number> = {};

    Object.entries(scanSummary[$scanSummaries.metrics])
      .filter(([_, summary]: [string, IRuleSummary]) => SharedCommonUtility.notNullish(summary[$ruleSummary.components]))
      .map(([ruleId, summary]: [string, IRuleSummary]) => {
        const componentMetrics: IRuleComponentSummaryMetrics = summary[$ruleSummary.components] || {};
        const componentsWithFinding: number = Object.values(componentMetrics).filter((metric: IRuleComponentMetrics) => {
          return metric[$ruleSummary.errors] > 0;
        }).length;
        if (componentsWithFinding > 0) {
          result[ruleId] = componentsWithFinding;
        }
      });

    return result;
  }

  public static getNumberOfPageFindingsByRule(pageMetrics: ISummaryMetrics[]): Record<string, number> {
    const result: Record<string, number> = {};

    const increment = (key: string): void => {
      if (SharedCommonUtility.isNullish(result[key])) {
        result[key] = 1;
      } else {
        result[key] += 1;
      }
    };

    pageMetrics.map((metrics: ISummaryMetrics) => {
      Object.entries(metrics).map(([ruleId, summary]: [string, IRuleSummary]) => {
        if (summary[$ruleSummary.triggered] && summary[$ruleSummary.errors] > 0) {
          increment(ruleId);
        }
      });
    });

    return result;
  }

  public static getSeverityForRule(
    tool: AccessibilityAuditToolNames,
    scanAudit: IScanAudit,
    ruleSummary: IRuleSummary,
  ): $severity {
    const auditForTool: IAudit = scanAudit[$scanAudit.audits][AuditToolNameToAuditType[tool]];
    const ruleName: string = ruleSummary[$ruleSummary.ruleName];
    const ruleInfo: IAuditRule = auditForTool.rules[ruleName];
    return ruleInfo[$auditRule.severity];
  }

  public static getRuleTitle(tool: AccessibilityAuditToolNames, scanAudit: IScanAudit, ruleSummary: IRuleSummary): string {
    const auditForTool: IAudit = scanAudit[$scanAudit.audits][AuditToolNameToAuditType[tool]];
    const ruleName: string = ruleSummary[$ruleSummary.ruleName];
    const ruleInfo: IAuditRule = auditForTool.rules[ruleName];
    return ruleInfo[$auditRule.title];
  }

  /**
   * Returns both the total number of success criteria and the number of success criteria with at least one finding.
   * @param tool
   * @param scanAudit
   * @param scanSummary
   * @returns [totalSuccessCriteria: number, totalSuccessCriteriaWithFindings: number]
   */
  public static getTotalSuccessCriteriaWithFindings(
    tool: AccessibilityAuditToolNames,
    scanAudit: IScanAudit,
    scanSummary: IScanSummary,
  ): [number, number] {
    if (SharedCommonUtility.isNullish(scanSummary?.[$scanSummaries.metrics])) {
      return [0, 0];
    }

    const successCriteria = new Set<string>();
    const successCriteriaWithFindings = new Set<string>();

    for (const ruleSummary of Object.values(scanSummary[$scanSummaries.metrics])) {
      const ruleName: string = ruleSummary[$ruleSummary.ruleName];
      const ruleInfo: IAuditRule = scanAudit[$scanAudit.audits][AuditToolNameToAuditType[tool]].rules[ruleName];
      const successCriteriaIdentifier: string[] = ruleInfo[$auditRule.wcagCriteria];

      const ruleHasFailures: boolean = ruleSummary[$ruleSummary.errors] > 0;

      successCriteriaIdentifier.forEach((identifier: string) => {
        successCriteria.add(identifier);
        if (ruleSummary[$ruleSummary.triggered] && ruleHasFailures) {
          successCriteriaWithFindings.add(identifier);
        }
      });
    }

    return [successCriteria.size, successCriteriaWithFindings.size];
  }

  public static getAgeInDays(createdAt: string | Date): number {
    let createdAtDate: Date;
    if (typeof createdAt === 'string') {
      createdAtDate = new Date(createdAt);
    } else {
      createdAtDate = createdAt;
    }
    return Math.floor((Date.now() - createdAtDate.getTime()) / SharedDateUtility.DAY_IN_MS);
  }

  public static isRuleNotApplicable(ruleSummary: IRuleSummary): boolean {
    return !ruleSummary[$ruleSummary.triggered] && ruleSummary[$ruleSummary.ignored] > 0;
  }

  public static getManualEvaluationStandards(evaluation: IManualScan | IManualEvaluation): EvaluationStandards[] {
    const scopeStandards: EvaluationStandards[] = evaluation[$manualAudit.scope]?.[$scanScope.standards];
    if (SharedCommonUtility.notNullishOrEmpty(scopeStandards)) {
      return EVALUATION_STANDARDS.filter((standard: EvaluationStandards) => scopeStandards.includes(standard));
    }

    const conformanceStandard: EvaluationStandards = EVALUATION_STANDARDS.find(
      (standard: EvaluationStandards) => String(standard) === String(evaluation.conformanceLevel),
    );
    return SharedCommonUtility.notNullish(conformanceStandard) ? [conformanceStandard] : [];
  }

  public static filterEvaluationStandardsBySuccessCriteria(
    evaluationStandards: EvaluationStandards[],
    successCriterias: IPopulatedManualFindingSuccessCriteria[],
  ): EvaluationStandards[] {
    return evaluationStandards.filter((evaluationStandard: EvaluationStandards): boolean => {
      return successCriterias.some((populuatedCriteria: IPopulatedManualFindingSuccessCriteria): boolean => {
        return SharedCommonUtility.notNullishOrEmpty(
          SharedAuditStandardsUtility.filterStandards(
            populuatedCriteria[$auditStandardSuccessCriteria.auditStandards],
            String(evaluationStandard),
          ),
        );
      });
    });
  }

  public static getEvaluationStandardFromAuditStandard(standard: IAuditStandardStandard): EvaluationStandards | undefined {
    switch (standard[$auditStandard.standard]) {
      case AuditDbStandard.wcag:
        const versionKey: string = `v${standard[$auditStandard.version].replace(/\./g, '')}`;
        return EvaluationStandards[`${standard[$auditStandard.standard]}_${versionKey}_${standard[$auditStandard.level]}`];
      case AuditDbStandard.section508:
        return EvaluationStandards.section508;
      case AuditDbStandard.en301549:
        return EvaluationStandards.en301549;
      default:
        return undefined;
    }
  }
}
