import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { IAuditRuleLibrary, IAuditRuleServerResponse } from '../../../shared/interfaces/audit-rule.interface';
import {
  ICustomAuditRuleWithStandards,
  IEditCustomAuditRules,
  IGetCustomAuditRulesQuery,
} from '../../../shared/interfaces/custom-audit-rule.interface';
import {
  ICreateCustomAuditRules,
  ICustomAuditDbRule,
  IExistsCustomAuditRulesQuery,
} from '../../../shared/interfaces/custom-audit-rule.interface';
import { RestService } from './rest.service';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { DEBOUNCE_TIME } from '../shared/constants';
import { validationError } from '../constants/form.constants';
import { IExistsResponse } from '../../../shared/interfaces/common.interface';
import { ICustomAuditRuleWithLibraryName } from '../components/audit-rule/audit-rule-view/audit-rule-view.component';
import { TranslateService } from '../translate/translate.service';
import { RuleLibraryOrigin } from '../../../shared/constants/rule-library';
import { $auditRule } from '../../../shared/constants/audit-rule';

@Injectable({
  providedIn: 'root',
})
export class CustomAuditRuleService {
  constructor(
    private restService: RestService,
    private translateService: TranslateService,
  ) {}

  public getRuleCategories(): Observable<string[]> {
    return this.restService.getCustomAuditRuleCategories();
  }

  public findCustomAuditRules(options?: IGetCustomAuditRulesQuery): Observable<IAuditRuleServerResponse> {
    return this.restService.findCustomAuditRules(options);
  }

  public findCustomAuditRuleByRuleId(ruleId: string): Observable<ICustomAuditRuleWithLibraryName> {
    return forkJoin([this.restService.findCustomAuditRuleByRuleId(ruleId), this.getCustomAuditRulesLibrary()]).pipe(
      map(
        ([customAuditRule, allLibraries]: [
          ICustomAuditRuleWithStandards,
          IAuditRuleLibrary[],
        ]): ICustomAuditRuleWithLibraryName => {
          const library: IAuditRuleLibrary = allLibraries.find(
            (lib: IAuditRuleLibrary): boolean => lib._id === customAuditRule.ruleLibrary,
          );
          const isAccessipediaRule: boolean =
            Number.isInteger(Number(ruleId)) || [RuleLibraryOrigin.la, RuleLibraryOrigin.legacyLa].includes(library?.origin);

          return {
            ...customAuditRule,
            libraryName: this.translateService.instant('my_rule_library'),
            testingMedium: this.translateService.instant(library.mediaType),
            isAccessipediaRule,
          };
        },
      ),
    );
  }

  public getCustomAuditRulesLibrary(): Observable<IAuditRuleLibrary[]> {
    return this.restService.getCustomAuditRulesLibrary();
  }

  public createCustomAuditRule(createData: ICreateCustomAuditRules): Observable<ICustomAuditDbRule> {
    return this.restService.createCustomAuditRule(createData);
  }

  public editCustomAuditRuleByRuleId(ruleEditRequest: IEditCustomAuditRules, ruleId: string): Observable<void> {
    return this.restService.editCustomAuditRuleByRuleId(ruleEditRequest, ruleId);
  }

  public exists(query: IExistsCustomAuditRulesQuery): Observable<IExistsResponse> {
    return this.restService.existsCustomAuditRule(query);
  }

  public validateRuleIdUniqueness(): (c: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (SharedCommonUtility.isNullishOrEmpty(control.value?.trim())) {
        return of(null);
      }

      return of(control.value).pipe(
        delay(DEBOUNCE_TIME),
        switchMap((ruleId: string) =>
          this.exists({ [$auditRule.ruleId]: ruleId }).pipe(
            map((response: IExistsResponse) =>
              response.exists ? { [validationError.masterLibraryRuleIdDuplicate]: true } : null,
            ),
          ),
        ),
      );
    };
  }

  public deleteRule(ruleId: string): Observable<void> {
    return this.restService.deleteCustomAuditRule(ruleId);
  }
}
